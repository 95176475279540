<template>
  <div class="app">
    <v-app-bar 
      fixed dark 
      flat 
      color="teal mb-1" 
    >
      <v-btn small icon>
        <v-avatar class="ml-2" size="30" @click.stop="drawer = !drawer" tile>
          <v-img :src="iconlistmenu"></v-img>
        </v-avatar>
      </v-btn>
      <v-row no-gutters>
        <a @click="home">
          <v-col class="text-center white--text title">
            <strong>{{ appname }}</strong>
          </v-col>
        </a>
      </v-row>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list>         
        <v-list-group
          v-for="item in imenu"
          :key="item.title"
          v-model="item.active"          
          no-action
        >  
          <template v-slot:activator>
            <v-list-item-content>
              <div class="d-flex">
                <v-avatar class="mt-0 mr-2 ml-2" size="35" tile>
                  <v-img :src="item.icon"></v-img>
                </v-avatar>
              <v-list-item-title v-text="item.title"></v-list-item-title>
              </div>
            </v-list-item-content>
          </template>
          <v-list-item v-for="child in item.items" :key="child.title" link>
            <v-list-item-content> 
              <v-list-item-title
                v-text="child.title"
                @click="menu(child)"
                class="text-body-2"
              ></v-list-item-title> 
            </v-list-item-content>
          </v-list-item>
        </v-list-group> 
      </v-list>
    </v-navigation-drawer>

    <v-footer app fixed dark flat color="teal" height="35" inset>
      <v-row justify="center" no-gutters>
        <v-col class="text-center white--text" cols="12">
          {{ new Date().getFullYear() }} — <strong>{{ appname }}</strong>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>
<style scoped>
.app {
  padding-bottom: 50px;
}
</style>

<script>
import Constant from "@/components/Constant.vue";
import axios from "axios";

export default {
  name: "Vmenu",
  data: () => ({ 
    drawer: null,
    appname: Constant.APP_NAME,
    iconlistmenu: Constant.iconlistmenu, 
    imenu: [
      {
        title: "Master",
        active: false,
        items: [
          { title: "Kas Dan Bank", kode: "bank" },
          // {title:'Barang', kode:'barang'},
          { title: "Merchant", kode: "cabang" },
          // {title:'gudang', kode:'gudang'},
          { title: "Customer", kode: "pelanggan" },
          // {title:'Suplier', kode:'pemasok'},
          // {title:'Salesman', kode:'penjual'},
          // {title:'Pengguna', kode:'pengguna'},
          { title: "Perkiraan", kode: "perkiraan" },
          { title: "Tipe Aktiva", kode: "tipeaktiva" },
          { title: "Kelompok Aktiva", kode: "kelompokaktiva" },
          // {title:'Hutang Awal', kode:'hutang'},
          // {title:'Piutang Awal', kode:'piutang'},
          // {title:'Persediaan Awal', kode:'persediaan'},
        ],
        action: "mdi-folder-multiple-outline",
        icon: Constant.API_ADDR + "assets/images/master.png"
      },
      {
        title: "Transaksi",
        active: false,
        items: [ 

          { title: "Pesanan Rental", kode: "pesananrental" },
          { title: "Pesanan Safari", kode: "pesananrental" },
          { title: "Pesanan Offroad", kode: "pesananoffroad" },
          { title: "Pesanan Onroad", kode: "pesananonroad" },
          { title: "Pendapatan Rental", kode: "jualrental" },
          { title: "Pendapatan Safari", kode: "jualrental" },
          { title: "Pendapatan Offroad", kode: "jualoffroad" },
          { title: "Pendapatan Onroad", kode: "jualonroad" },

          { title: "Kas Keluar", kode: "kask" },
          { title: "Kas Masuk", kode: "kasm" },
          { title: "Penyusutan Aktiva", kode: "penyusutan" },
          { title: "Jurnal Umum", kode: "jurnalumum" },
          { title: "Jurnal Penyesuaian", kode: "jurnalp" },
        ],
        action: "mdi-chart-areaspline",
        icon: Constant.API_ADDR + "assets/images/transaksi.png"
      },
      {
        title: "Laporan",
        active: false,
        items: [
          { title: "L. Pesanan Rental", kode: "laporanpsnrental" },
          { title: "L. Pesanan Safari", kode: "laporanpsnsafari" },
          { title: "L. Pesanan Offroad", kode: "laporanpsnoffroad" },
          { title: "L. Pesanan Onroad", kode: "laporanpsnonroad" },
          { title: "L. Pendapatan Rental", kode: "laporanpnjrental" },
          { title: "L. Pendapatan Safari", kode: "laporanpnjsafari" },
          { title: "L. Pendapatan Offroad", kode: "laporanpnjoffroad" },
          { title: "L. Pendapatan Onroad", kode: "laporanpnjonroad" },
          { title: "L. Keuangan", kode: "laporankeuangan" },
        ],
        action: "mdi-fax",
        icon: Constant.API_ADDR + "assets/images/laporan.png"
      },
      {
        title: "Setting",
        active: false,
        items: [
          { title: "Setting", kode: "setting" },
          { title: "Setting Akun", kode: "settingpos" },
        ],
        action: "mdi-hammer-wrench",
        icon: Constant.API_ADDR + "assets/images/tools.png"
      },
    ],
  }),
  created() { 
    if (Constant.USER_APP == "" && Constant.TOKEN_APP == "") {
      this.$router.push({ name: "Home" });
    } else {
      // this.imenu = Constant.ITEMMENU
    } 
  },
  methods: {
    menu(item) {
      if (Constant.IMENU != item.title) {
        Constant.MENU_APP = "Daftar " + item.title;
        this.$router.push({ name: item.title });
      }
    },
    home() {
      if (Constant.IMENU != "Dashboard") {
        this.$router.push({ name: "Dashboard" });
      }
    },
  },
  mounted() {
    
  },
};
</script>

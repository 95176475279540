<script>
// const API_ADDR1 = "http://localhost/trip/";
const API_ADDR1 = "https://api.saebo.id/trip/";
const TOKEN_APP = "";
const USER_APP = "";
const USER_NAME = "";
const IDLOG_APP = "";
const BRANCH_APP = "";
const MENU_APP = "";
const IDH = 0;
const IDD = 0;
const JNS = "TAMBAH";
const FRM = "DAFTAR";
const IMENU = "";
const PERIODE = "";
const ITEMMENU = [];

export default {
  API_ADDR: API_ADDR1,
  BASE_URL: null,
  IDH: IDH,
  IDD: IDD,
  JNS: JNS,
  FRM: FRM,
  APP_NAME: "",
  TOKEN_APP: TOKEN_APP,
  USER_APP: USER_APP,
  USER_NAME: USER_NAME,
  IDLOG_APP: IDLOG_APP,
  BRANCH_APP: BRANCH_APP,
  MENU_APP: MENU_APP,
  IMENU: IMENU,
  PERIODE: PERIODE,
  STARTDATE: "",
  ITEMMENU: ITEMMENU,
  ConHeader: {
    headers: {
      "Content-Type": "multipart/form-data; charset=UTF-8",
    },
  },
  iconadd: API_ADDR1 + "assets/images/add.png",
  iconedit: API_ADDR1 + "assets/images/edit.png",
  icondelete: API_ADDR1 + "assets/images/delete.png",
  iconfilter: API_ADDR1 + "assets/images/filter.png",
  iconlistmenu: API_ADDR1 + "assets/images/listmenu.png",
  iconprinter: API_ADDR1 + "assets/images/printer.png",
  iconrefresh: API_ADDR1 + "assets/images/refresh.png", 
  icondownload: API_ADDR1 + "assets/images/download.png", 
  iconitemmenu: API_ADDR1 + "assets/images/transaction.png", 
  // icon item menu 
  iconpesananj: API_ADDR1 + "assets/images/pesanan.png", 
  iconpendapatan: API_ADDR1 + "assets/images/pendapatan.png", 
  
  iconkask: API_ADDR1 + "assets/images/kask.png", 
  iconkasm: API_ADDR1 + "assets/images/kasm.png", 
  iconjurnal: API_ADDR1 + "assets/images/jurnal.png", 


};
</script>

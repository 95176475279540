import Vue from "vue";
import VueRouter from "vue-router"; 
import Vueexam from "../components/HelloWorld.vue"; 
import Login from "../components/Login.vue"; 
import Dashboard from "../components/Dashboard.vue"; 

import Bank from "../components/bank/Daftarbank.vue"; 
import BankManage from "../components/bank/Managebank.vue";

import TipeAktiva from "../components/tipeaktiva/Daftartipe.vue"; 
import TipeAktivaManage from "../components/tipeaktiva/Managetipe.vue";

import KelompokAktiva from "../components/kelompokaktiva/Daftarkelompok.vue"; 
import KelompokAktivaManage from "../components/kelompokaktiva/Managekelompok.vue";

import Customer from "../components/customer/Daftarcustomer.vue"; 
import Merchant from "../components/merchant/Daftarmerchant.vue"; 

import Perkiraan from "../components/perkiraan/Daftarperkiraan.vue";
import PerkiraanManage from "../components/perkiraan/Manageperkiraan.vue";

import PesananRental from "../components/pesananrental/Daftarpesananrental.vue";



import PesananSafari from "../components/pesanansafari/Daftarpesanansafari.vue";

import PesananOffroad from "../components/pesananoffroad/Daftarpesananoffroad.vue";

import PesananOnroad from "../components/pesananonroad/Daftarpesananonroad.vue";

import PenjualanRental from "../components/penjualanrental/Daftarpenjualanrental.vue";
import PenjualanSafari from "../components/penjualansafari/Daftarpenjualansafari.vue";
import PenjualanOffroad from "../components/penjualanoffroad/Daftarpenjualanoffroad.vue";
import PenjualanOnroad from "../components/penjualanonroad/Daftarpenjualanonroad.vue";

import JurnalUmum from "../components/ju/Daftarju.vue";
import JurnalPenyesuaian from "../components/jurnalp/Daftarjurnalp.vue";
import JurnalPManage from "../components/jurnalp/Managejurnalp.vue";
import KasKeluar from "../components/kask/Daftarkask.vue";
import KasKManage from "../components/kask/Managekask.vue";
import KasMasuk from "../components/kasm/Daftarkasm.vue";
import KasMManage from "../components/kasm/Managekasm.vue";

import Penyusutan from "../components/penyusutan/Daftarpenyusutan.vue";
import PenyusutanManage from "../components/penyusutan/Managepenyusutan.vue";


import LaporanPsnRental from "../components/lappesananrental/Daftarlappesananrental.vue";
import LaporanPsnSafari from "../components/lappesanansafari/Daftarlappesanansafari.vue";
import LaporanPsnOffroad from "../components/lappesananoffroad/Daftarlappesananoffroad.vue";
import LaporanPsnOnroad from "../components/lappesananonroad/Daftarlappesananonroad.vue";

import LaporanPnjRental from "../components/lappenjualanrental/Daftarlappenjualanrental.vue";
import LaporanPnjSafari from "../components/lappenjualansafari/Daftarlappenjualansafari.vue";
import LaporanPnjOffroad from "../components/lappenjualanoffroad/Daftarlappenjualanoffroad.vue";
import LaporanPnjOnroad from "../components/lappenjualanonroad/Daftarlappenjualanonroad.vue";

import LaporanKeuangan from "../components/lapkeuangan/Daftarlapkeuangan.vue"; 
import PrintNeraca from "../components/lapkeuangan/Printneraca.vue";
import PrintNeracaLajur from "../components/lapkeuangan/Printneracalajur.vue";
import PrintBB from "../components/lapkeuangan/Printbb.vue";
import PrintJurnal from "../components/lapkeuangan/Printjurnal.vue";
import PrintLaba from "../components/lapkeuangan/Printlr.vue";


import Setting from "../components/setting/Daftarsetting.vue";
import SettingPos from "../components/settingpos/Daftarsettingpos.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Login
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  }, 
  {
    path: "/bank",
    name: "Kas Dan Bank",
    component: Bank,
  },
  {
    path: "/bank/manage",
    name: "Kas Dan Bank Manage",
    component: BankManage,
  },   
  {
    path: "/tipeaktiva",
    name: "Tipe Aktiva",
    component: TipeAktiva,
  },
  {
    path: "/tipeaktiva/manage",
    name: "Tipe Aktiva Manage",
    component: TipeAktivaManage,
  }, 

  {
    path: "/kelompokaktiva",
    name: "Kelompok Aktiva",
    component: KelompokAktiva,
  },
  {
    path: "/kelompokaktiva/manage",
    name: "Kelompok Aktiva Manage",
    component: KelompokAktivaManage,
  }, 

  {
    path: "/pelanggan",
    name: "Customer",
    component: Customer,
  },
  {
    path: "/cabang",
    name: "Merchant",
    component: Merchant,
  },
  {
    path: "/perkiraan",
    name: "Perkiraan",
    component: Perkiraan,
  },
  {
    path: "/perkiraan/manage",
    name: "Perkiraan Manage",
    component: PerkiraanManage,
  },
  {
    path: "/pesananrental",
    name: "Pesanan Rental",
    component: PesananRental,
  },
  {
    path: "/pesanansafari",
    name: "Pesanan Safari",
    component: PesananSafari,
  },
  {
    path: "/pesananoffroad",
    name: "Pesanan Offroad",
    component: PesananOffroad,
  },
  {
    path: "/pesananonroad",
    name: "Pesanan Onroad",
    component: PesananOnroad,
  },
  {
    path: "/penjualanrental",
    name: "Pendapatan Rental",
    component: PenjualanRental,
  },
  {
    path: "/penjualansafari",
    name: "Pendapatan Safari",
    component: PenjualanSafari,
  },
  {
    path: "/penjualanoffroad",
    name: "Pendapatan Offroad",
    component: PenjualanOffroad,
  },
  {
    path: "/penjualanonroad",
    name: "Pendapatan Onroad",
    component: PenjualanOnroad,
  },
  
  {
    path: "/jurnalumum",
    name: "Jurnal Umum",
    component: JurnalUmum,
  },
  {
    path: "/jurnalp",
    name: "Jurnal Penyesuaian",
    component: JurnalPenyesuaian,
  },
  {
    path: "/jurnalp/managejurnalp",
    name: "Jurnal Penyesuaian Manage",
    component: JurnalPManage,
  }, 
  {
    path: "/kask",
    name: "Kas Keluar",
    component: KasKeluar,
  },
  {
    path: "/kask/managekask",
    name: "Transaksi Kas Keluar Manage",
    component: KasKManage,
  }, 
  {
    path: "/kasm",
    name: "Kas Masuk",
    component: KasMasuk,
  },
  {
    path: "/kasm/managekasm",
    name: "Transaksi Kas Masuk Manage",
    component: KasMManage,
  }, 

  {
    path: "/penyusutan",
    name: "Penyusutan Aktiva",
    component: Penyusutan,
  },
  {
    path: "/penyusutan/managepenyusutan",
    name: "Penyusutan Aktiva Manage",
    component: PenyusutanManage,
  }, 
  {
    path: "/laporanpsnrental",
    name: "L. Pesanan Rental",
    component: LaporanPsnRental,
  }, 
  {
    path: "/laporanpsnsafari",
    name: "L. Pesanan Safari",
    component: LaporanPsnSafari,
  }, 
  {
    path: "/laporanpsnoffroad",
    name: "L. Pesanan Offroad",
    component: LaporanPsnOffroad,
  }, 
  {
    path: "/laporanpsnonroad",
    name: "L. Pesanan Onroad",
    component: LaporanPsnOnroad,
  }, 
  {
    path: "/laporanpnjrental",
    name: "L. Pendapatan Rental",
    component: LaporanPnjRental,
  }, 
  {
    path: "/laporanpnjsafari",
    name: "L. Pendapatan Safari",
    component: LaporanPnjSafari,
  }, 
  {
    path: "/laporanpnjoffroad",
    name: "L. Pendapatan Offroad",
    component: LaporanPnjOffroad,
  }, 
  {
    path: "/laporanpnjonroad",
    name: "L. Pendapatan Onroad",
    component: LaporanPnjOnroad,
  },  
  {
    path: "/laporankeuangan",
    name: "L. Keuangan",
    component: LaporanKeuangan,
  },   
  {
    path: "/printneraca",
    name: "Neraca",
    component: PrintNeraca,
  },
  {
    path: "/printbukubesar",
    name: "Buku Besar",
    component: PrintBB,
  },
  {
    path: "/printjurnal",
    name: "L. Jurnal Umum",
    component: PrintJurnal,
  },
  {
    path: "/printlaba",
    name: "L. Laba Rugi",
    component: PrintLaba,
  },
  {
    path: "/printneracalajur",
    name: "L. Neraca Lajur",
    component: PrintNeracaLajur,
  },

  {
    path: "/setting",
    name: "Setting",
    component: Setting,
  }, 
  {
    path: "/settingpos",
    name: "Setting Akun",
    component: SettingPos,
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

<template>
  <v-app id="inspire">
    <Vmenu />
    <!-- <v-container>
      <v-row>
        <v-col cols="12" sm="8" >
          <v-card flat>
            
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" >
          <v-card
            class="pa-2"
            outlined
            tile
          >
            One of three columns
          </v-card>
        </v-col>
      </v-row>
    </v-container> -->
    
    <!-- asli neng kene -->
    <v-container>
      <v-row>
        <v-col v-for="mainmenu in menus" :key="mainmenu.id" cols="6" sm="3" md="3">
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 8 : 2" @click="OpenMenu(mainmenu)">
                <div class="d-flex justify-start">
                  <v-avatar class="ma-2" size="50" tile>
                    <v-img :src="mainmenu.image"></v-img>
                  </v-avatar>
                  <div class="ml-n3 d-flex flex-column">
                    <v-list-item dense>
                      <v-list-item-content >
                        <v-list-item-title class="text-body-2 pb-2" >
                          {{ mainmenu.name }}
                        </v-list-item-title> 
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </div>
            </v-card>
          </v-hover>
        </v-col>
      </v-row> 
    </v-container>

    <v-container class="mt-n4">
      <v-row>
        <v-col v-for="itemmenu in itemmenus" :key="itemmenu.id" cols="4" sm="3" md="3"> 
          <v-col align="center">
            <v-btn small icon class="ma-1">
              <v-avatar size="50" @click="KlikMenu(itemmenu.name)" tile>
                <v-img :src="itemmenu.icon"></v-img>
              </v-avatar>
            </v-btn> 
          </v-col>
          <v-card-text class="subtitle-2 text-center mt-n6">
            {{itemmenu.name}}
          </v-card-text>
        </v-col>
      </v-row>
    </v-container> 
    
    
  </v-app>
</template>
 
<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from 'axios';

export default {
  components: {
    Vmenu,
  },
  data() {
    return {
      loading: true,
      wrnbtn:"teal darken-1",
      iconitemmenu: Constant.iconitemmenu,
      menus: [
        //{id:1, name:'Pembelian', image:'http://localhost/trip/assets/images/shopping-bag.png'}, 'http://localhost/trip/assets/images/promotion.png
        {id:2, name:'Order', image: Constant.API_ADDR+ "assets/images/promotion.png" },
        //{id:3, name:'Persediaan', image:'http://localhost/trip/assets/images/video-marketing.png'},
        {id:4, name:'Keuangan', image: Constant.API_ADDR+ "assets/images/account.png"},
      ],
      listmenus: [
        // {id:111, name:'Pembelian', modul:'Pembelian', route:''},
        
        {id:211, name:'Pesanan Rental', modul:'Order', route:'pesananrental', icon: Constant.iconpesananj },
        {id:212, name:'Pesanan Safari', modul:'Order', route:'pesanansafari', icon: Constant.iconpesananj},
        {id:213, name:'Pesanan Offroad', modul:'Order', route:'pesananoffroad', icon: Constant.iconpesananj},
        {id:214, name:'Pesanan Onroad', modul:'Order', route:'pesananonroad', icon: Constant.iconpesananj},
        {id:215, name:'Pendapatan Rental', modul:'Order', route:'penjualanrental', icon: Constant.iconpendapatan},
        {id:216, name:'Pendapatan Safari', modul:'Order', route:'penjualansafari', icon: Constant.iconpendapatan},
        {id:217, name:'Pendapatan Offroad', modul:'Order', route:'penjualanoffroad', icon: Constant.iconpendapatan},
        {id:218, name:'Pendapatan Onroad', modul:'Order', route:'penjualanonroad', icon: Constant.iconpendapatan},  
        
        {id:411, name:'Kas Keluar', modul:'Keuangan', route:'kask', icon: Constant.iconkask},  
        {id:412, name:'Kas Masuk', modul:'Keuangan', route:'kasm', icon: Constant.iconkasm},  
        {id:413, name:'Penyusutan Aktiva', modul:'Keuangan', route:'penyusutan', icon: Constant.iconjurnal},  
        {id:414, name:'Jurnal Umum', modul:'Keuangan', route:'jurnalumum', icon: Constant.iconjurnal},  
        {id:415, name:'Jurnal Penyesuaian', modul:'Keuangan', route:'jurnalp', icon: Constant.iconjurnal},  
        
      ],
      itemmenus: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    async getdata() {
      this.loading = true;
      this.loading = false;
      const datakirim = {
            username: Constant.USER_APP,
            token: Constant.TOKEN_APP,
            idlog: Constant.IDLOG_APP
        };
      /*
        await axios
            .post(Constant.API_ADDR + "login/getmenu", datakirim)
            .then((response) => { 
              if (response.data.status == 102 || response.data.status == '102') 
              {
                this.imenu = response.data.data;
                var getdata =  this.imenu.filter(function(value) {
                  return value.nomorheader == "0";
                });
                console.log(getdata)

                let bigCities = this.datatabel.filter(city => city.nomorheader == 1);
                console.log(bigCities)
              } 
            })
            .catch((errros) => {
              console.log("error");
              Constant.TOKEN_APP = "";
              Constant.USER_APP = "";
              Constant.IDLOG_APP = "";
              Constant.BRANCH_APP = "";  
            });
            */
    },
    KlikMenu(item) 
    { 
      if(item != '')
      {
        Constant.MENU_APP = 'Daftar ' +item;
        this.$router.push({ name: item }); 
      }
    }, 
    async OpenMenu(mainmenu) {
      this.itemmenus = this.listmenus.filter(i => i.modul == mainmenu.name);
    }
  },
  created() {
    Constant.IMENU = 'Dashboard';  
  },
  async mounted() {},
};
</script>

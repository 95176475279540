<template>
  <v-app id="inspire">
    <Vmenu /> 
    <div class="forme">
      <v-container>
        <div>
          <div class="mt-3 title font-weight-bold">
            {{ menus }}
            <v-btn x-small icon max-width="35px" class="ml-2">
              <v-avatar size="35" @click="getmaster" tile>
                <v-img :src="icondownload"></v-img>
              </v-avatar>
            </v-btn>  
          </div> 
          <div class="text-center">
            
          </div> 
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          > 
            <v-row align="center" no-gutters>   
              <v-col cols="12" sm="6">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tgl"
                      label="Tanggal" 
                      class="pa-1"
                      persistent-hint 
                      v-bind="attrs" 
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tgl"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu> 
              </v-col>
              <v-col cols="12" sm="6"  >
                <v-text-field
                  v-model="nobukti"
                  :rules="NoRules"
                  label="Nobukti"
                  class="pa-1"
                  required 
                  @focus="getnewcode"
                ></v-text-field>
              </v-col>  
              <v-col cols="12" sm="6">
                <v-autocomplete 
                  v-model="tipe.nomor"
                  :items="datatipe"
                  item-text="desk"
                  item-value="nomor"  
                  :rules="[v => !!v || 'Item is required']"
                  label="Tipe Aktiva"
                  class="pa-1"
                  required  
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6"></v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="nama" 
                  label="Nama Aktiva" 
                  class="pa-1"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-autocomplete 
                  v-model="kelompok.nomor"
                  :items="datakelompok"
                  item-text="desk"
                  item-value="nomor"  
                  :rules="[v => !!v || 'Item is required']"
                  label="Kelompok Aktiva"
                  class="pa-1"
                  required  
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6"></v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete 
                  v-model="akunbiaya.nomor"
                  :items="dataakun"
                  item-text="desk"
                  item-value="nomor"  
                  :rules="[v => !!v || 'Item is required']"
                  label="Akun Biaya"
                  class="pa-1"
                  required  
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete 
                  v-model="akunakumulasi.nomor"
                  :items="dataakun"
                  item-text="desk"
                  item-value="nomor"  
                  :rules="[v => !!v || 'Item is required']"
                  label="Akun Akumulasi"
                  class="pa-1"
                  required  
                ></v-autocomplete>
              </v-col>        
              <v-col cols="12" sm="6">
                <v-text-field
                  type="number"
                  v-model="amount"
                  label="Nilai Perolehan" 
                  class="pa-1"
                ></v-text-field>
              </v-col>     
              <v-col cols="12" sm="6">
                <v-text-field
                  type="number"
                  v-model="sisa"
                  label="Nilai Sisa" 
                  class="pa-1"
                ></v-text-field>
              </v-col>        
              <!-- tabel -->
              <v-col cols="12" class="pa-1">
                <v-card flat>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" width="70px">
                              Bln-Ke
                            </th>
                            <th class="text-left">
                              Tgl
                            </th>
                            <th class="text-left">
                              Nobukti
                            </th>                            
                            <th class="text-right">
                              Jumlah
                            </th>  
                          </tr>
                        </thead>
                        <tbody>
                          <tr 
                            v-for="item in datatabel"
                            :key="item.idd"
                          >
                            <td>{{ item.bln }}</td> 
                            <td>{{ item.tgl }}</td>
                            <td>{{ item.nobukti }}</td>
                            <td class="text-right">{{ formatPrice(item.jumlah) }}</td>   
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                </v-card>  
              </v-col>  

              <v-col cols="12" sm="12" class="pa-1">
                <v-btn color="primary" small
                  width="75"
                  :loading="loading"
                  :disabled="loading"
                  @click="simpandata">
                  Simpan
                </v-btn> 
                <v-btn color="error" small width="75" class="ml-2" @click="batal">
                  batal
                </v-btn> 
                <v-btn color="success" small width="75" class="ml-2" @click="tambahdata">
                  Tambah
                </v-btn>   
              </v-col>                
            </v-row>
          </v-form>
        </div> 
      </v-container>
    </div>
     
  </v-app>
</template>

<style scoped>
  .forme {
    width: 100%;
    height: 100%; 
    position: relative; 
    background-color: grey lighten-5;
  } 
</style>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from "axios";

export default {
  components: {
    Vmenu,
  },
  data() {
    return {
      menu: Constant.MENU_APP,      
      valid: true,
      menu1:false,
      loading:false,
      loadcetak:false,
      icondownload: Constant.icondownload,
      iconedit: Constant.iconedit,
      iconhapus: Constant.icondelete, 
      vidh:-1,
      vjenis:'tambahdata',
      menus:'', 
      amount:0, 
      sisa:0, 
      tgl: new Date().toISOString().substr(0, 10), 
      nobukti:'',
      nama:'',  
      akunbiaya: { nomor: "0", desk: "-Pilih Akun Biaya-" }, 
      akunakumulasi: { nomor: "0", desk: "-Pilih Akun Akumulasi-" }, 
      dataakun: [{nomor: "0", desk: "-Pilih Akun Perkiraan-"}],   
      datatipe: [{nomor: "0", desk: "-Tipe Aktiva-"}],  
      tipe: { nomor: "0", desk: "-Tipe Aktiva-" }, 
      kelompok: { nomor: "0", desk: "-Tipe Aktiva-" },  
      datakelompok: [{nomor: "0", desk: "-Kelompok Aktiva-"}],  
      datatabel:[],
      NoRules: [
        v => !!v || 'Field is required', 
      ],    
    };
  },
  methods: { 
    getnewcode()
    {
      this.loading = true;  
      if(this.vjenis == 'tambahdata')
      { 
        const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP, 
          search: this.tgl
        };  
        axios
          .post(Constant.API_ADDR + "penyusutan/nobuktibaru", datakirim)
          .then((response) => {  
            this.nobukti = response.data.data  
          })
          .catch((errros) => {
            console.log("error");
          }); 
      }
      this.loading = false;  
    },
    simpandata()
    {
      this.loading = true;   
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP,  
        jenis: this.vjenis,
        idh: this.vidh,
        nobukti: this.nobukti,
        tanggal: this.tgl,
        nama: this.nama, 
        akunbiaya: this.akunbiaya.nomor,
        akunakumulasi: this.akunakumulasi.nomor,
        amount: this.amount, 
        sisa: this.sisa, 
        tipe: this.tipe.nomor,
        kelompok: this.kelompok.nomor,
      };  
      axios
        .post(Constant.API_ADDR + "penyusutan/simpanpenyusutan", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          {  
            this.vjenis = 'editdata'
            this.vidh = response.data.datah.id
            this.datatabel = response.data.datad 
            this.loading = false; 
          }
          else
          {
            alert(response.data.message)
            this.loading = false; 
          }

        })
        .catch((errros) => {
          console.log("error");
          this.loading = false; 
        });   
    },
    batal(){ 
      this.$router.push({ name: "Penyusutan Aktiva" }); 
    },
    async getmaster()
    {
      this.loading = true; 
      this.dataakun = [];      
      this.datatipe = [];      
      this.datakelompok = [];      
      this.tipe.nomor = 0;
      this.kelompok.nomor = 0;
      this.akunbiaya.nomor = 0;
      this.akunakumulasi.nomor = 0;
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP,  
      };  
      await axios
        .post(Constant.API_ADDR + "api/datamaster", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.dataakun = response.data.dataakun4
            this.datatipe = response.data.datatipeaktiva
            this.datakelompok = response.data.datakelompokaktiva
          }
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false; 
    },
    tambahdata(){
      this.vidh = -1
      this.vjenis = 'tambahdata' 
      this.nobukti = ''
      this.tgl = new Date().toISOString().substr(0, 10) 
      this.nama = ""
      this.amount = 0
      this.sisa = 0
      this.datatabel = []
      this.akunbiaya.nomor = "0"
      this.akunakumulasi.nomor = "0"
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  },
  created() {
    Constant.IMENU = "Manage Penyusutan Aktiva";
    this.menus = Constant.IMENU;
    this.vidh = this.$route.params.Id
    this.vjenis = this.$route.params.Jenis  
    this.getmaster(); 
    this.nobukti = this.$route.params.nobukti
    this.tgl = this.$route.params.tgl    
    this.nama = this.$route.params.nama
    this.amount = this.$route.params.amount 
    this.sisa = this.$route.params.sisa
    this.tipe.nomor = this.$route.params.tipe
    this.kelompok.nomor= this.$route.params.kelompok
    this.datatabel = this.$route.params.datatabel 
    this.akunbiaya.nomor = this.$route.params.akunbiaya 
    this.akunakumulasi.nomor = this.$route.params.akunakumulasi 
  },
  mounted() {},
  watch: {},
};
</script>

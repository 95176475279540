<template> 
  <v-container fluid justify="center">
    <v-row width=100% justify="start" class="ml-3">
      <v-btn depressed color="primary" small v-if="print === '0'" @click="printdata()">PRINT</v-btn>
    </v-row>
    <v-row class="text-center mt-n4"> 
      <v-col>  
        <p class="subtitle-2 font-weight-bold mb-0">
          {{ office }}
        </p> 
        <p class="text-subtitle-2 red--text font-weight-bold mt-n2 mb-0">
          {{ jenis }}
        </p> 
        <p class="text-overline font-weight-regular mt-n2 mb-0">
          {{ periode }}
        </p>
      </v-col> 
    </v-row>

    <div class="container-fluid">
      <v-row justify="center" width=100%> 
        <v-col cols="6">
          <v-progress-linear
            color="teal accent-4"
            indeterminate
            rounded
            height="4" 
            :active="progress"
        ></v-progress-linear> 
        </v-col>
      </v-row>
    </div>

    <v-container fluid>
      <v-row width="100%" class="ml-1 mr-2">
        <table class="tabel">
          <thead v-for="item in datatabel"
              :key="item.ky">
            <tr>
              <td colspan="2" class="font-weight-bold" style="font-size:12px;padding:5px;">{{ item.judul }}</td> 
            </tr> 
            <tr style="background-color: teal; color: white;">
              <td class="text-left font-weight-bold">Desc</td>
              <td class="text-right font-weight-bold" style="width:150px;">Saldo</td> 
            </tr>
            <tr 
              v-for="child in item.d"
              :key="child.urut"
            > 
              <td :class="getclassheader(child.levelno)">
                <v-btn v-if="print === '0' && child.levelno === '4'"
                  color="teal"
                  class="mx-2"
                  fab 
                  max-height="10px"
                  max-width="10px"
                  @click="editItem(child)"
                >  
                </v-btn>   
                {{ child.nama }}</td>                  
              <td class="text-right">{{ formatPrice(child.saldoakhir) }}</td>   
            </tr> 
            <tr style="padding:10px;"> 
              <td class="text-left font-weight-bold" style="font-size:12px;">{{ item.judul }}</td>  
              <td class="text-right font-weight-bold" style="font-size:12px;">{{ formatPrice(item.ts) }}</td> 
            </tr>  
          </thead>
          <tbody>
          <tr style="padding:10px; background-color: teal; color: white;"> 
            <td class="text-left font-weight-bold" style="font-size:12px;">LABA / RUGI BERSIH</td>  
            <td class="text-right font-weight-bold" style="font-size:12px;">{{ formatPrice(total) }}</td> 
          </tr> 
          </tbody>
        </table>
      </v-row>
    </v-container>        
  </v-container>   
</template> 

<style> 
  .tabel {
    font-family: Arial, Helvetica, sans-serif; 
    font-size: 11px;   
    border-collapse: collapse;
    width: 100%;      
  }

  .tabel td, .tabel th {
    border: 0.3px solid rgba(255, 255, 255, 0.89);
    padding: 5px;
  }

  .tabel tr:nth-child(even){background-color: #f2f2f2;}

  .tabel tr:hover {background-color: #ddd;}

  .tabel th {
    padding-top: 2px;
    padding-bottom: 10px;
    text-align: center;
    background-color: teal;
    color: white;
    font-size: 11px;     
  }
</style>


<script>
import axios from "axios";  
import Constant from "@/components/Constant.vue";

export default {
  name: "LJU",

  data: () => ({ 
    token: "",
    datatabel : [],
    office: "",
    jenis: "",
    periode: "",   
    total: 0,
    print:0,
    progress: true
  }),
  methods: { 
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getclassheader(level){ 
      if(level != 4)
      {
        return "text-left font-weight-bold" 
      }
      return "text-left font-weight-regular" 
    },
    async getdata(){  
      await axios 
        .post(localStorage.getItem("API_ADDR") + "viewlapkeu/viewlaporan", {token: this.token})
        .then((response) => {
          if (response.data.status === 102 || response.data.status === "102") 
          { 
            this.datatabel = response.data.data
            this.jenis = response.data.jenis
            this.periode = response.data.periode           
            this.office = response.data.office
            this.total = response.data.total
            this.progress = false
            this.cetakdata()
          } else {
            console.log("error");  
          }
        })
        .catch((errros) => {
          console.log("error"); 
        });
       
    },
    editItem(item){
      const datakirim = {
        user: localStorage.getItem('USER_APP'),
        token: localStorage.getItem('TOKEN_APP'),
        idlog: localStorage.getItem('IDLOG_APP'), 
        jenis: 'Buku Besar',  
        aksi: 'PDF',  
        periode: this.periode,  
        pfilter1: 'SEMUA',  
        pfilter2: 'norek',  
        pfilter3: 'Sama Dengan',  
        pfilter4: item.norek   
      };  
      axios
      .post(Constant.API_ADDR + "laporankeuangan/request", datakirim) 
      .then((response) => {   
        if(response.data.status == 102)
        { 
          let routeData = this.$router.resolve({ name: 'Buku Besar', query: {noinv: response.data.data, print:0 }});              
          window.open(routeData.href, '_blank'); 
        }
        else
        {
          if(response.data.status == 100)
          {
            localStorage.clear()
            alert('Session Expired')
          }
          else
          {
            alert(response.data.message)
          } 
        }
      })
      .catch((errros) => {
        console.log("error");
      })

    },
    printdata(){
      window.open(Constant.API_ADDR + "printpreviewlapkeu/cetak/" + this.token)  
    },
  },
  created() 
  {
    this.token = this.$route.query.noinv 
    this.print = this.$route.query.print  
    this.getdata() 
  },

};
</script>

<style>
  @page {
    size: A4 portrait; 
  }  
</style>
<template>
  <v-app id="inspire">
    <Vmenu />
    <v-card flat>
      <v-card-title class="mb-n4">
        {{ menu }} 
      </v-card-title>

      <v-card flat>
        <v-row align="center" class="ml-3 mt-0"> 
          <v-btn
            tile
            small
            color="primary"
            class="mx-1"
            @click="manage" 
          > 
            <v-avatar size="25" tile>
              <v-img :src="iconadd"></v-img>
            </v-avatar>
            Tambah
          </v-btn>
          <v-btn
            tile
            small
            color="success"
            class="ml-1" 
            @click="renewdata" 
          >
            <v-avatar size="25" tile>
              <v-img :src="iconrefresh"></v-img>
            </v-avatar>
            Refresh
          </v-btn> 
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            no-gutters
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                tile
                small
                class="ml-1"
                color="error" 
                width="100px"               
                v-bind="attrs"
                v-on="on"                
              > 
                <v-avatar size="25" tile>
                  <v-img :src="iconfilter"></v-img>
                </v-avatar>
                Filter
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>                    
                    <v-col cols="12">
                      <v-text-field
                        v-model="srnomor"
                        label="Nomor"
                        hint="Search Nomor"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="srnama"
                        label="Nama"
                        hint="Search Nama"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="srnoheader"
                        label="Nomor Header"
                        hint="Nomor Header"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="srposisidk"
                        label="Posisi DK"
                        hint="Posisi DK"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="srposisinr"
                        label="Posisi N/R"
                        hint="Posisi N/R"
                        persistent-hint
                      ></v-text-field>
                    </v-col>                    
                    <v-col cols="6"></v-col>  
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="getdata"
                >
                  Search
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> 
        </v-row>  
      </v-card>
             
      <div class="col">
        <v-card flat>       
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Nomor
                  </th> 
                  <th class="text-left">
                    Nama
                  </th>
                  <th class="text-left">
                    Jenis Level
                  </th>
                  <th class="text-left">
                    Level 
                  </th>
                  <th class="text-left">
                    No Header
                  </th>
                  <th class="text-left">
                    Posisi DK
                  </th>
                  <th class="text-left">
                    Posisi N/R
                  </th>
                  <th class="text-left">
                    Posisi Neraca
                  </th>
                  <th class="text-left">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in datatabel"
                  :key="item.no"
                >
                  <td>{{ item.nomor }}</td> 
                  <td>{{ item.nama }}</td>
                  <td>{{ item.jenislevel }}</td>
                  <td>{{ item.levelno }}</td>
                  <td>{{ item.noheader }}</td>
                  <td>{{ item.posisidk }}</td> 
                  <td>{{ item.posisinr }}</td> 
                  <td>{{ item.posisineraca }}</td> 
                  <td>
                    <v-btn x-small icon max-width="15px" class="ma-1">
                      <v-avatar size="15" @click="editItem(item)" tile>
                        <v-img :src="iconedit"></v-img>
                      </v-avatar>
                    </v-btn>
                    <v-btn x-small icon max-width="15px" class="ma-1">
                      <v-avatar  size="15" @click="deleteItem(item)" tile>
                        <v-img :src="iconhapus"></v-img>
                      </v-avatar>
                    </v-btn>  
                  </td> 
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from 'axios'; 

export default {
  components: {
    Vmenu
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      search: "", 
      datatabel: [],  
      loading: true, 
      iconedit: Constant.iconedit,
      iconhapus: Constant.icondelete,
      iconadd: Constant.iconadd,
      iconrefresh: Constant.iconrefresh,
      iconfilter: Constant.iconfilter,
      dialog:false,
      srnomor:"",
      srnama:"",
      srnoheader:"",
      srposisidk:"",
      srposisinr:"" 
    };
  },
  methods: {
    async getdata() {  
      this.loading = true; 
      const datakirim = {
        idlog: Constant.IDLOG_APP, 
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,        
        search: this.search,    
        srnomor: this.srnomor,
        srnama: this.srnama,
        srnoheader: this.srnoheader,
        srposisidk: this.srposisidk,
        srposisinr: this.srposisinr, 
      };  
     
      await axios
        .post(Constant.API_ADDR + "perkiraan/daftarperkiraan", datakirim)
        .then(response => {
          if (response.data.status === 102) 
          {
            this.datatabel = response.data.data;  
            this.srnomor = "";
            this.srnama = "";
            this.srnoheader = "";
            this.srposisidk = "";
            this.srposisinr = ""; 
          }  
          else
          {
           if(response.data.status == 100)
           {
             alert(response.data.message)
             this.Constant.USER_APP = '';
             this.Constant.TOKEN_APP = ''; 
           } 
          }
        })
        .catch(errros => {
          console.log("error");
        }); 
      this.loading = false;  
      this.dialog = false;
    },
    editItem(item) { 
       this.$router.push({ name: "Perkiraan Manage", 
        params: { Id: item.id, Jenis:'editdata', 
        nomor:item.nomor, nama:item.nama, keterangan:item.keterangan, 
        jenislevel:item.jenislevel, levelno:item.levelno, nomorheader:item.nomorheader, 
        posisidk:item.posisidk, posisinr:item.posisinr, posisineraca:item.posisineraca } 
        });
    },
    deleteItem(item) {
      this.loading = true;     
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP,  
        idperkiraan: item.id 
      };  
      axios
        .post(Constant.API_ADDR + "perkiraan/hapusperkiraan", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            alert('sukses menghapus data')
            this.renewdata()  
          }
          else
          {
            alert(response.data.message)
          }
        })
        .catch((errros) => {
          console.log("error");
        });  
      this.loading = false;  
    },    
    renewdata() 
    {
      this.srnomor = "";
      this.srnama = "";
      this.srnoheader = "";
      this.srposisidk = "";
      this.srposisinr = ""; 
      this.getdata();
    }, 
    manage()
    {  
      if (Constant.USER_APP != "" && Constant.TOKEN_APP != "") 
      { 
        this.$router.push({ name: "Perkiraan Manage", 
        params: { Id: '-1', Jenis:'tambahdata', 
        nomor:'', nama:'', keterangan:'', 
        jenislevel:'DETIL', levelno:4, nomorheader:'0', 
        posisidk:'DEBET', posisinr:'NERACA', posisineraca:'DEBET'   } });
      } 
    },
    filterheader(data)
    {
      data.filter(checkAdult);
    },
    checkheader(header) {
      return header == 100;
    },
  },
  created() 
  {
    Constant.IMENU = 'Perkiraan';
    this.getdata();
  },
  mounted() {},
};
</script>

<template>
  <v-app id="inspire">
    <Vmenu />
    <v-card flat>
      <v-card-title>
        {{ menu }}
      </v-card-title>
      <v-card flat class="mt-n4">
          <v-tabs 
            centered
            fixed-tabs 
            show-arrows
          > 
            <v-tab href="#Pembelian">
              Pembelian
            </v-tab> 
              <v-tab-item id="Pembelian" key="Pembelian"> 
                <v-container>
                  <v-row no-gutters >
                      <v-col cols="12">
                        <div class="pa-2">
                          <v-autocomplete 
                            v-model="selectedbelikas.nomor"
                            :items="dataakun"
                            clearable
                            item-text="desk"
                            item-value="nomor"  
                            label="Kas Pembelian"
                          ></v-autocomplete> 
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="pa-2">
                          <v-autocomplete 
                            v-model="selectedbelihutang.nomor"
                            :items="dataakun"
                            clearable
                            item-text="desk"
                            item-value="nomor" 
                            label="Hutang Dagang"
                          ></v-autocomplete>   
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="pa-2">
                          <v-autocomplete 
                            v-model="selectedbeli.nomor"
                            :items="dataakun"
                            clearable
                            item-text="desk"
                            item-value="nomor"  
                            label="Pembelian" 
                          ></v-autocomplete>   
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="pa-2 mt-n2">
                          <v-autocomplete 
                            v-model="selectedbelidiskon.nomor"
                            :items="dataakun"
                            clearable
                            item-text="desk"
                            item-value="nomor"  
                            label="Diskon" 
                          ></v-autocomplete>                             
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="pa-2 mt-n2">
                          <v-autocomplete 
                            v-model="selectedbelippn.nomor"
                            :items="dataakun"
                            clearable
                            item-text="desk"
                            item-value="nomor"  
                            label="PPN Masukan"
                          ></v-autocomplete>                            
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="pa-2 mt-n2">
                          <v-autocomplete 
                            v-model="selectedbeliretur.nomor"
                            :items="dataakun"
                            clearable
                            item-text="desk"
                            item-value="nomor"  
                            label="Retur Pembelian"
                          ></v-autocomplete>  
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="pa-2 mt-n2">
                          <v-autocomplete 
                            v-model="selectedbelilabaretur.nomor"
                            :items="dataakun"
                            clearable
                            item-text="desk"
                            item-value="nomor"  
                            label="Laba Retur Pembelian"
                          ></v-autocomplete> 
                        </div>
                      </v-col> 
                      <v-col cols="12">
                        <div class="pa-2 mt-n2">
                          <v-autocomplete 
                            v-model="selectedbelirugiretur.nomor"
                            :items="dataakun"
                            clearable
                            item-text="desk"
                            item-value="nomor"  
                            label="Rugi Retur Pembelian"
                          ></v-autocomplete>  
                        </div>
                      </v-col> 
                  </v-row>
                </v-container> 
              </v-tab-item> 

            <v-tab href="#Penjualan">
              Penjualan
            </v-tab> 
              <v-tab-item id="Penjualan" key="Penjualan">
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <div class="pa-2">
                        <v-autocomplete 
                          v-model="selectedjualkas.nomor"
                          :items="dataakun"
                          clearable
                          item-text="desk"
                          item-value="nomor"   
                          label="Kas Penjualan" 
                        ></v-autocomplete>   
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="pa-2">
                        <v-autocomplete 
                          v-model="selectedjualpiutang.nomor"
                          :items="dataakun"
                          clearable
                          item-text="desk"
                          item-value="nomor"   
                          label="Piutang Dagang" 
                        ></v-autocomplete>                            
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="pa-2">
                        <v-autocomplete 
                          v-model="selectedjualtunai.nomor"
                          :items="dataakun"
                          clearable
                          item-text="desk"
                          item-value="nomor"   
                          label="Jual Tunai" 
                        ></v-autocomplete>                             
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="pa-2 mt-n2">
                        <v-autocomplete 
                          v-model="selectedjualkredit.nomor"
                          :items="dataakun"
                          clearable
                          item-text="desk"
                          item-value="nomor"   
                          label="Jual Kredit" 
                        ></v-autocomplete>                            
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="pa-2 mt-n2">
                        <v-autocomplete 
                          v-model="selectedjualdiskon.nomor"
                          :items="dataakun"
                          clearable
                          item-text="desk"
                          item-value="nomor"   
                          label="Diskon Penjualan" 
                        ></v-autocomplete>  
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="pa-2 mt-n2">
                        <v-autocomplete 
                          v-model="selectedjualppn.nomor"
                          :items="dataakun"
                          clearable
                          item-text="desk"
                          item-value="nomor"   
                          label="PPN Keluaran" 
                        ></v-autocomplete>   
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="pa-2 mt-n2">
                        <v-autocomplete 
                          v-model="selectedjualpersediaan.nomor"
                          :items="dataakun"
                          clearable
                          item-text="desk"
                          item-value="nomor"   
                          label="Persediaan" 
                        ></v-autocomplete>   
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="pa-2 mt-n2">
                        <v-autocomplete 
                          v-model="selectedjualhpp.nomor"
                          :items="dataakun"
                          clearable
                          item-text="desk"
                          item-value="nomor"   
                          label="HPP" 
                        ></v-autocomplete>   
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="pa-2 mt-n2">
                        <v-autocomplete 
                          v-model="selectedjualstokkurang.nomor"
                          :items="dataakun"
                          clearable
                          item-text="desk"
                          item-value="nomor"  
                          cache-items
                          label="Stok Opname Kurang" 
                        ></v-autocomplete>  
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="pa-2 mt-n2">
                        <v-autocomplete 
                          v-model="selectedjualstoklebih.nomor"
                          :items="dataakun"
                          clearable
                          item-text="desk"
                          item-value="nomor"   
                          label="Stok Opname Lebih" 
                        ></v-autocomplete>   
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="pa-2 mt-n2">
                        <v-autocomplete 
                          v-model="selectedjualpakai.nomor"
                          :items="dataakun"
                          clearable
                          item-text="desk"
                          item-value="nomor"   
                          label="Pemakaian" 
                        ></v-autocomplete>  
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="pa-2 mt-n2">
                        <v-autocomplete 
                          v-model="selectedjualpendapatan.nomor"
                          :items="dataakun"
                          clearable
                          item-text="desk"
                          item-value="nomor"   
                          label="Pendapatan Lainnya" 
                        ></v-autocomplete>                             
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="pa-2 mt-n2">
                        <v-autocomplete 
                          v-model="selectedjualretur.nomor"
                          :items="dataakun"
                          clearable
                          item-text="desk"
                          item-value="nomor"   
                          label="Retur Penjualan" 
                        ></v-autocomplete>   
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="pa-2 mt-n2">
                        <v-autocomplete 
                          v-model="selectedjualbiaya.nomor"
                          :items="dataakun"
                          clearable
                          item-text="desk"
                          item-value="nomor"   
                          label="Biaya Penjualan" 
                        ></v-autocomplete>   
                      </div>
                    </v-col>
                  </v-row>
                </v-container>      
              </v-tab-item> 
            <v-tab href="#Modal">
              Modal
            </v-tab> 
              <v-tab-item id="Modal" key="Modal">
                <v-container>
                  <v-row no-gutters>
                     <v-col cols="12">
                        <div class="pa-2">
                          <v-autocomplete 
                            v-model="selectedmodalthlalu.nomor"
                            :items="dataakun"
                            clearable
                            item-text="desk"
                            item-value="nomor"   
                            label="Laba S.D Tahun Lalu" 
                          ></v-autocomplete>  
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="pa-2">
                          <v-autocomplete 
                            v-model="selectedmodalthjalan.nomor"
                            :items="dataakun"
                            clearable
                            item-text="desk"
                            item-value="nomor"   
                            label="Laba Tahun Berjalan" 
                          ></v-autocomplete>   
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="pa-2">
                          <v-autocomplete 
                            v-model="selectedmodalperiodejalan.nomor"
                            :items="dataakun"
                            clearable
                            item-text="desk"
                            item-value="nomor"   
                            label="Laba Periode Berjalan"  
                          ></v-autocomplete>  
                        </div>
                      </v-col>
                  </v-row>
                </v-container>  
              </v-tab-item>   
          </v-tabs>  
        </v-card>
    </v-card>

    <v-container>
      <v-row no-gutters>
        <v-col cols="12">
          <div class="pa-2">
            <v-btn color="primary" 
              :loading="loading"
              :disabled="loading"
              @click="simpandata">
              Simpan
            </v-btn> 
            <v-btn color="error" class="ml-2" @click="batal">
              batal
            </v-btn>   
          </div>
        </v-col> 
      </v-row>
    </v-container>   
  </v-app>
</template>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from 'axios'; 

export default {
  components: {
    Vmenu
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      tabs: null,  
      loading: false,
      selectedbelikas: { },
      selectedbelihutang: { },
      selectedbeli: { },
      selectedbelidiskon: { },
      selectedbelippn: { },
      selectedbeliretur: { },
      selectedbelilabaretur: { },
      selectedbelirugiretur: { },
      
      selectedjualkas: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" },
      selectedjualpiutang: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" },
      selectedjualtunai: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" },
      selectedjualkredit: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" },
      selectedjualdiskon: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" },
      selectedjualppn: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" },
      selectedjualpersediaan: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" },
      selectedjualhpp: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" },
      selectedjualstokkurang: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" },
      selectedjualstoklebih: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" },
      selectedjualpakai: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" },
      selectedjualpendapatan: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" },
      selectedjualretur: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" },
      selectedjualbiaya: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" },

      selectedmodalthlalu: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" },
      selectedmodalthjalan: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" },
      selectedmodalperiodejalan: { nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan" }, 
      dataakun: [{nomor: "-0000-", desk: "-0000-|| Pilih Akun Perkiraan"}],  
      editdata:0,
      vid:0,

    };
  },
  methods: {
    async getdata() {  
      this.loading = true; 
      const datakirim = {
        idlog: Constant.IDLOG_APP, 
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP
      };   
      await axios
        .post(Constant.API_ADDR + "settingpos/datapos", datakirim)
        .then(response => {
          if (response.data.status === 102) 
          {  
            this.selectedbelikas.nomor = response.data.data.beli_kas; 
            this.selectedbelihutang.nomor = response.data.data.hutangdagang; 
            this.selectedbeli.nomor = response.data.data.pembelian
            this.selectedbelidiskon.nomor =  response.data.data.beli_discount; 
            this.selectedbelippn.nomor =  response.data.data.ppnmasukan; 
            this.selectedbeliretur.nomor = response.data.data.returbeli;  
            this.selectedbelilabaretur.nomor =  response.data.data.rbeli_laba; 
            this.selectedbelirugiretur.nomor =  response.data.data.rbeli_rugi; 

            this.selectedjualkas.nomor =  response.data.data.jual_kas; 
            this.selectedjualpiutang.nomor =  response.data.data.piutangdagang; 
            this.selectedjualtunai.nomor =  response.data.data.jualtunai; 
            this.selectedjualkredit.nomor =  response.data.data.jualkredit; 
            this.selectedjualdiskon.nomor =  response.data.data.jual_discount; 
            this.selectedjualppn.nomor =  response.data.data.ppnkeluaran; 
            this.selectedjualpersediaan.nomor =  response.data.data.persediaan; 
            this.selectedjualhpp.nomor = response.data.data.hpp; 
            this.selectedjualstokkurang.nomor =  response.data.data.opname_fisik_kurang; 
            this.selectedjualstoklebih.nomor =  response.data.data.opname_fisik_lebih; 
            this.selectedjualpakai.nomor = response.data.data.pemakaian; 
            this.selectedjualpendapatan.nomor =  response.data.data.jual_lain; 
            this.selectedjualretur.nomor = response.data.data.returjual; 
            this.selectedjualbiaya.nomor = response.data.data.jual_biaya; 

            this.selectedmodalthlalu.nomor =  response.data.data.labatahunlalu; 
            this.selectedmodalthjalan.nomor =  response.data.data.labatahunberjalan; 
            this.selectedmodalperiodejalan.nomor = response.data.data.lababulanberjalan; 
            this.editdata = response.data.data.edit; 
            this.vid = response.data.data.id; 
          }  
          else
          {
           if(response.data.status == 100)
           {
             alert(response.data.message)
            //  this.Constant.USER_APP = '';
            //  this.Constant.TOKEN_APP = ''; 
           } 
          }
        })
        .catch(errros => {
          console.log("error");
        }); 
      this.loading = false;  
    },
    simpandata()
    {  
      if(this.editdata == 1 || this.editdata == '1')
      {
        this.loading = true;
        // kirim data
        const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP, 
          jenis: 'editdata',
          id: this.vid,
          belikas:this.selectedbelikas.nomor,
          belihutang:this.selectedbelihutang.nomor,
          beli:this.selectedbeli.nomor,
          belidiskon:this.selectedbelidiskon.nomor,
          belippn:this.selectedbelippn.nomor,
          beliretur:this.selectedbeliretur.nomor,
          belilabaretur:this.selectedbelilabaretur.nomor,
          belirugiretur:this.selectedbelirugiretur.nomor,
          jualkas:this.selectedjualkas.nomor,
          jualpiutang:this.selectedjualpiutang.nomor,
          jualtunai:this.selectedjualtunai.nomor,
          jualkredit:this.selectedjualkredit.nomor,
          jualdiskon:this.selectedjualdiskon.nomor,
          jualppn:this.selectedjualppn.nomor,
          jualpersediaan:this.selectedjualpersediaan.nomor,
          jualhpp:this.selectedjualhpp.nomor,
          jualstokkurang:this.selectedjualstokkurang.nomor,
          jualstoklebih:this.selectedjualstoklebih.nomor,
          jualpakai:this.selectedjualpakai.nomor,
          jualpendapatan:this.selectedjualpendapatan.nomor,
          jualretur:this.selectedjualretur.nomor,
          jualbiaya:this.selectedjualbiaya.nomor,
          modalthlalu:this.selectedmodalthlalu.nomor,
          modalthjalan:this.selectedmodalthjalan.nomor,
          modalperiodejalan:this.selectedmodalperiodejalan.nomor,          
        };
        axios
          .post(Constant.API_ADDR + "settingpos/simpandata", datakirim)
          .then((response) => {
            if (response.data.status == 102) 
            { 
              this.loading = false;
              alert(response.data.message)
            } else {
              this.loading = false;
              alert(response.data.message);
            }
          })
          .catch((errros) => {
            this.loading = false;
            console.log("error");
          }); 
      }
      else
      {
        alert('Hak Akses Dibatasi');
      }
    },
    batal(){ 
      this.$router.push({ name: "Dashboard" }); 
    },
    async getpos(val){ 
      this.loading = true; 
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP 
      };  
      await axios
        .post(Constant.API_ADDR + "api/datamaster", datakirim)
        .then((response) => { 
          this.dataakun = response.data.dataakun
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false;
    }, 
  },
  created() 
  {
    Constant.IMENU = 'Setting Akun';
    this.getpos();
    this.getdata();
  },
  mounted() {},
  watch: {}
};
</script>

<template> 
  <v-container fluid>     
    <v-row width=100% justify="start" class="ml-3">
      <v-btn depressed color="primary" small v-if="print === '0'" @click="printdata()">PRINT</v-btn> 
    </v-row>
    <v-row class="text-center"> 
      <v-col>  
        <p class="subtitle-2 font-weight-bold mb-0">
          {{ office }}
        </p> 
        <p class="text-subtitle-2 red--text font-weight-bold mt-n2 mb-0">
          {{ jenis }}
        </p> 
        <p class="text-overline font-weight-regular mt-n2 mb-0">
          {{ periode }}
        </p>
      </v-col> 
    </v-row>  

    <div class="container-fluid">
      <v-row justify="center" width=100%> 
        <v-col cols="6">
          <v-progress-linear
            color="teal accent-4"
            indeterminate
            rounded
            height="4" 
            :active="progress"
        ></v-progress-linear> 
        </v-col>
      </v-row>
    </div>

    <v-container fluid>
      <v-row width="100%" class="ml-1 mr-2">
        <table class="tabel">
          <thead v-for="item in datatabel"
              :key="item.ky">
            <tr>
              <td colspan="4" class="font-weight-bold">{{ item.nobukti }} </td> 
            </tr> 
            <tr style="background-color: teal; color: white;">
              <td class="text-left font-weight-bold">Date</td>
              <td class="text-left font-weight-bold">Desc</td>
              <td class="text-right font-weight-bold" style="width:150px;">Debet</td>
              <td class="text-right font-weight-bold" style="width:150px;">Kredit</td> 
            </tr>
            <tr 
              v-for="child in item.d"
              :key="child.urut"
            > 
              <td class="text-left">{{ child.tgl }} </td>
              <td class="text-left">{{ child.norek }} - {{ child.nama}}</td> 
              <td class="text-right">{{ formatPrice(child.debet) }}</td>  
              <td class="text-right">{{ formatPrice(child.kredit) }}</td>   
            </tr> 
            <tr style="padding:10px;">
              <td></td>
              <td></td>
              <td class="text-right font-weight-bold" style="font-size:12px;">{{ formatPrice(item.td) }}</td>  
              <td class="text-right font-weight-bold" style="font-size:12px;">{{ formatPrice(item.tk) }}</td>             
            </tr> 
          </thead>
        </table>
      </v-row>
    </v-container>  
  </v-container>  
</template> 

<style> 
  .tabel {
    font-family: Arial, Helvetica, sans-serif; 
    font-size: 11px;   
    border-collapse: collapse;
    width: 100%;      
  }

  .tabel td, .tabel th {
    border: 0.3px solid rgba(255, 255, 255, 0.89);
    padding: 5px;
  }

  .tabel tr:nth-child(even){background-color: #f2f2f2;}

  .tabel tr:hover {background-color: #ddd;}

  .tabel th {
    padding-top: 2px;
    padding-bottom: 10px;
    text-align: center;
    background-color: teal;
    color: white;
    font-size: 11px;     
  }
</style>



<script>
import Constant from "@/components/Constant.vue";
import axios from "axios";  

export default {
  name: "LJU",

  data: () => ({ 
    token: "",
    datatabel : [],
    office: "",
    jenis: "",
    periode: "",   
    print:0,
    progress: true
  }),
  methods: { 
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getclassheader(level){ 
      if(level != 4)
      {
        return "text-caption font-weight-black" 
      }
      return "text-caption font-weight-regular" 
    },
    async getdata(){  
      await axios 
        .post(localStorage.getItem("API_ADDR") + "viewlapkeu/viewlaporan", {token: this.token})
        .then((response) => {
          if (response.data.status === 102 || response.data.status === "102") 
          {
            this.datatabel = response.data.data
            this.jenis = response.data.jenis
            this.periode = response.data.periode           
            this.office = response.data.office
            this.progress = false
            this.cetakdata()
          } else {
            this.progress = false
            console.log("error");  
          }
        })
        .catch((errros) => {
          this.progress = false
          console.log("error"); 
        });
       
    },
    editItem(item){

    },
    printdata()
    { 
      window.open(Constant.API_ADDR + "printpreviewlapkeu/cetak/" + this.token)     
    },
    cetakdata(){
      if(this.print == 1)
      {
        // setTimeout(function () {
        //   window.print();
        //   // window.onafterprint = window.close()
        // }, 5000); 

      }   
    }
  },
  created() 
  {
    Constant.IMENU = 'L. Jurnal Umum';
    this.token = this.$route.query.noinv 
    this.print = this.$route.query.print 
    this.getdata() 
  },

};
</script>

 
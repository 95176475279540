<template>
  <v-app id="inspire">
    <Vmenu /> 
    <div class="forme">
      <v-container>
        <div>
          <div class="mt-3 title font-weight-bold">
            {{ menus }}
          </div> 
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          > 
            <v-row align="center" no-gutters>  
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="norek"
                  :rules="NoRules"
                  label="No Rekening"
                  required
                  class="pa-1"
                  ref="norek"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8"></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="nama"
                  :rules="NoRules"
                  label="Nama"
                  required
                  class="pa-1"
                  ref="nama"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="8"></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="kode"
                  :rules="NoRules"
                  label="Kode"
                  required
                  class="pa-1"
                  @focus="getnewcode"
                  ref="kode"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="8"></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="pemilik"
                  label="Pemilik"
                  class="pa-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8"></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="alamat" 
                  label="Alamat" 
                  class="pa-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8"></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="telp" 
                  label="No Telp" 
                  :rules="NumRules"  
                  class="pa-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8"></v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete 
                  v-model="selected.nomor"
                  :items="dataakun"
                  item-text="desk"
                  item-value="nomor"  
                  cache-items
                  :rules="[v => !!v || 'Item is required']"
                  label="POS Akun"
                  required  
                  class="pa-1" 
                ></v-autocomplete>   
              </v-col> 
              <v-col cols="12" sm="8"></v-col>

              <v-col cols="12" sm="6" class="pa-1">
                <v-btn color="primary" 
                  :loading="loading"
                  :disabled="loading"
                  @click="simpandata">
                  Simpan
                </v-btn> 
                <v-btn color="error" class="ml-2" @click="batal">
                  batal
                </v-btn>  
              </v-col>  
            </v-row>
          </v-form>
        </div> 
      </v-container>
    </div>
     
  </v-app>
</template>

<style scoped>
  .forme {
    width: 100%;
    height: 100%; 
    position: relative; 
    background-color: grey lighten-5;
  } 
</style>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from "axios";

export default {
  components: {
    Vmenu,
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      loading:false,
      valid: true,
      vid:-1,
      vjenis:'tambahdata',
      menus:'', 
      selected: { nomor: "0", desk: "0 || Pilih Akun Perkiraan" }, 
      dataakun: [{nomor: "0", desk: "0 || Pilih Akun Perkiraan"}],  
      search: null, 
      norek:'',
      nama:'',
      kode:'',
      pemilik:'',
      alamat:'',
      telp:'',
      NumRules:[
        v => /^[0-9]+$/.test(v) || 'Must be Number Only' 
      ],
      NoRules: [
        v => !!v || 'Field is required', 
      ],    
    };
  },
  methods: { 
    getnewcode()
    {
      this.loading = true;  
      if(this.vjenis == 'tambahdata')
      { 
        const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP, 
          search: this.nama
        };  
        axios
          .post(Constant.API_ADDR + "bank/getnewcode", datakirim)
          .then((response) => {
            if(response.data.status == 102)
            {  
              this.kode = response.data.data  
            }
            else
            { 
              alert(response.data.message)         
              this.$refs.nama.focus()
            }   
          })
          .catch((errros) => {
            console.log("error");
          }); 
      }
      this.loading = false;  
    },
    simpandata()
    {
      this.loading = true;    
      var validasiAngka = /^[0-9]+$/;
      if(this.telp.length > 0)
      {
        if (!this.telp.match(validasiAngka)) 
        { 
          alert('Format Telp Salah')
          return false
        } 
      }  
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        jenis: this.vjenis,
        id: this.vid,
        kode: this.kode,
        nama: this.nama,
        norek: this.norek,
        pemilik: this.pemilik,
        alamat: this.alamat,
        telp: this.telp,
        pos: this.selected.nomor
      };  
      axios
        .post(Constant.API_ADDR + "bank/simpandata", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.$refs.form.reset()
            this.selected.nomor = "-0000-" 
            this.vid            = -1
            this.vjenis         = 'tambahdata'
            this.loading        = false; 
          }
          else
          {
            alert(response.data.message)
            this.loading = false; 
          } 
        })
        .catch((errros) => {
          this.loading = false; 
          console.log("error");
        });   
    },
    batal(){ 
      this.$router.push({ name: "Kas Dan Bank" }); 
    }, 
    async getmaster(){
      this.loading = true; 
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP 
      };  
      await axios
        .post(Constant.API_ADDR + "api/datamaster", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.dataakun = response.data.dataakun4
          }
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false; 
    },
  },
  created() {
    Constant.IMENU = "Manage Kas Dan Bank";
    this.getmaster(); 
    this.menus = Constant.IMENU;
    this.vid = this.$route.params.Id
    this.vjenis = this.$route.params.Jenis 
    this.norek = this.$route.params.norek
    this.nama = this.$route.params.nama
    this.kode = this.$route.params.kode
    this.pemilik = this.$route.params.pemilik
    this.alamat = this.$route.params.alamat
    this.telp = this.$route.params.telp 
    this.selected.nomor = this.$route.params.pos 
  },
  mounted() {},
  watch: {},
};
</script>

<template>
  <v-app id="inspire">
    <Vmenu />    
    <v-card flat>
      <v-card-title class="mb-n4">
        {{ menu }} 
      </v-card-title>
      <v-card flat>
        <v-row align="center" class="ml-3 mt-0"> 
          <v-btn
            tile
            small
            color="primary"
            class="ml-1"
            @click="manage"
          >
            <v-avatar size="25" tile>
              <v-img :src="iconadd"></v-img>
            </v-avatar>
            Tambah
          </v-btn> 
          <v-btn
            tile
            small
            color="success"
            class="ml-1"
            @click="renewdata"
          >
            <v-avatar size="25" tile>
              <v-img :src="iconrefresh"></v-img>
            </v-avatar>
            Refresh
          </v-btn> 
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            no-gutters
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                tile
                small
                class="ml-1"
                color="error"                
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar size="25" tile>
                  <v-img :src="iconfilter"></v-img>
                </v-avatar> 
                Filter
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-menu
                        ref="menudari"
                        v-model="menudari"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dari"
                            label="Dari"
                            hint="YYYY-MM-DD"
                            persistent-hint 
                            v-bind="attrs" 
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="dari"
                          no-title
                          @input="menudari = false"
                        ></v-date-picker>
                      </v-menu> 
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-menu
                        ref="menusmp"
                        v-model="menusmp"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="sampai"
                            label="Sampai"
                            hint="YYYY-MM-DD"
                            persistent-hint
                            v-bind="attrs" 
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="sampai"
                          no-title
                          @input="menusmp = false"
                        ></v-date-picker>
                      </v-menu> 
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="srnobukti"
                        label="Nobukti"
                        hint="Search Nobukti"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="srkodebank"
                        label="Kode Bank"
                        hint="Search Kode Bank"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="srnamabank"
                        label="Nama Bank"
                        hint="Search Nama Bank"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="getdata"
                >
                  Search
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> 
        </v-row>         
      </v-card>
      
      <!-- tabel -->
      <div class="col">
        <v-card flat>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      No
                    </th>
                    <th class="text-left">
                      Tgl
                    </th>
                    <th class="text-left">
                      Nobukti
                    </th>
                    <th class="text-left">
                      Kodebank
                    </th>
                    <th class="text-left">
                      Nama Bank
                    </th>
                    <th class="text-right">
                      Total
                    </th>
                    <th class="text-left">
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in datatabel"
                    :key="item.no"
                  >
                    <td>{{ item.no }}</td>
                    <td>{{ item.tgl }}</td>
                    <td>{{ item.nobukti }}</td>
                    <td>{{ item.kodebank }}</td>
                    <td>{{ item.namabank }}</td>
                    <td class="text-right">{{ formatPrice(item.totalkredit) }}</td> 
                    <td>
                      <v-btn x-small icon max-width="15px" class="ma-1">
                        <v-avatar size="15" @click="editItem(item)" tile>
                          <v-img :src="iconedit"></v-img>
                        </v-avatar>
                      </v-btn>
                      <v-btn x-small icon max-width="15px" class="ma-1">
                        <v-avatar  size="15" @click="deleteItem(item)" tile>
                          <v-img :src="iconhapus"></v-img>
                        </v-avatar>
                      </v-btn>  
                    </td> 
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-card>  
      </div>  
    </v-card> 
    <!-- modal filter --> 
  </v-app>
</template>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from 'axios'; 

export default {
  components: {
    Vmenu
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      menudari: false,
      menusmp: false,
      dialog: false,
      iconedit: Constant.iconedit,
      iconhapus: Constant.icondelete,
      iconadd: Constant.iconadd,
      iconrefresh: Constant.iconrefresh,
      iconfilter: Constant.iconfilter,
      dari: new Date().toISOString().substr(0, 10), 
      sampai: new Date().toISOString().substr(0, 10), 
      menu1: false,
      menu2: false,
      srnobukti: "",   
      srkodebank: "",
      srnamabank: "",
      datatabel: [],       
      loading: true,   
    };
  },
  methods: {
    async getdata() {  
      this.loading = true; 
      const datakirim = {
        idlog: Constant.IDLOG_APP, 
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP, 
        dari:this.dari,
        sampai:this.sampai,
        srnobukti: this.srnobukti,    
        srkodebank: this.srkodebank,
        srnamabank: this.srnamabank
      };   
     
      await axios
        .post(Constant.API_ADDR + "kasm/datakasmasuk", datakirim)
        .then(response => {
          if (response.data.status === 102) 
          {
            this.datatabel = response.data.data; 
            this.srnobukti = ""
            this.srkodebank = ""
            this.srnamabank = ""
          }  
          else
          {
           if(response.data.status == 100)
           {
             alert(response.data.message)
             this.Constant.USER_APP = '';
             this.Constant.TOKEN_APP = ''; 
           } 
          }
        })
        .catch(errros => {
          console.log("error");
        }); 
      this.loading = false;  
      this.dialog = false;
    },
    editItem(item) { 
      this.$router.push({ name: "Transaksi Kas Masuk Manage", 
        params: { Id: item.id, Jenis:'editdata',  
        totaldebet:item.totaldebet, totalkredit:item.totalkredit, tgl:item.tgl, nobukti:item.nobukti, 
        idbank:item.idbank, desk1:item.deskripsi, datatabel:item.datad }      
      });
    },
    deleteItem(item) {
      this.loading = true;    
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        idkask: item.id 
      };  
      axios
        .post(Constant.API_ADDR + "kasm/hapuskash", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            alert('sukses menghapus data')
            this.renewdata()  
          }
          else
          {
            alert(response.data.message)
          }
        })
        .catch((errros) => {
          console.log("error");
        });  
      this.loading = false;  
    },    
    renewdata() 
    { 
      this.srnobukti = ""
      this.srkodebank = ""
      this.srnamabank = ""
      this.getdata();
    },
    manage()
    {  
      if (Constant.USER_APP != "" && Constant.TOKEN_APP != "") 
      { 
        this.$router.push({ name: "Transaksi Kas Masuk Manage", 
        params: { Id: '-1', Jenis:'tambahdata',  
        totaldebet:0, totalkredit:0,  tgl:new Date().toISOString().substr(0, 10), nobukti:'', 
        idbank:"0", desk1:'', datatabel:[] } });
      } 
    },    
    caridata()
    {
      this.dialog = false;
      this.getdata();
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  },
  created() 
  {
    Constant.IMENU = 'Transaksi Kas Masuk';
    this.dari = Constant.STARTDATE;    
    this.getdata();
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>

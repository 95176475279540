<template>
  <v-app id="inspire">
    <Vmenu />
    <v-card flat>
      <v-card-title class="mb-n4">
        {{ menu }} 
      </v-card-title>
      <v-card flat>
        <v-row align="center" class="ml-3 mt-0">           
          <v-btn
            tile
            small
            color="success"
            class="ml-1"
            @click="renewdata"
          >
            
            <v-avatar size="25" tile>
              <v-img :src="iconrefresh"></v-img>
            </v-avatar>
            Refresh
          </v-btn> 
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            no-gutters
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                tile
                small
                class="ml-1"
                color="error"                
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar size="25" tile>
                  <v-img :src="iconfilter"></v-img>
                </v-avatar>
                Filter
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-menu
                        ref="menudari"
                        v-model="menudari"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dari"
                            label="Dari"
                            hint="YYYY-MM-DD"
                            persistent-hint 
                            v-bind="attrs" 
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="dari"
                          no-title
                          @input="menudari = false"
                        ></v-date-picker>
                      </v-menu> 
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-menu
                        ref="menusmp"
                        v-model="menusmp"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="sampai"
                            label="Sampai"
                            hint="YYYY-MM-DD"
                            persistent-hint
                            v-bind="attrs" 
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="sampai"
                          no-title
                          @input="menusmp = false"
                        ></v-date-picker>
                      </v-menu> 
                    </v-col>
                    <v-col cols="12" class="mt-n2">
                      <v-text-field
                        v-model="srnobukti"
                        label="Nobukti"
                        hint="Search Nobukti"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mt-n2">
                      <v-text-field
                        v-model="srcustomer"
                        label="Customer"
                        hint="Search Customer"
                        persistent-hint
                      ></v-text-field>
                    </v-col> 
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="mt-n4">
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="getdata"
                >
                  Search
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>  
        </v-row> 
        
      </v-card>
       
      <div class="col">
        <v-card flat>       
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    #No
                  </th> 
                  <th class="text-left">
                    Tanggal
                  </th> 
                  <th class="text-left">
                    Nobukti
                  </th>
                  <th class="text-left">
                    Nama Customer
                  </th>
                  <th class="text-left">
                    Status
                  </th>
                  <th class="text-right">
                    Jumlah
                  </th>
                  <th class="text-right">
                    Diskon 
                  </th>
                  <th class="text-right">
                    Unik 
                  </th>
                  <th class="text-right">
                    Netto
                  </th> 
                  <th class="text-left">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in datatabel"
                  :key="item.no"
                >
                  <td>{{ item.no }}</td> 
                  <td>{{ item.tgl }}</td> 
                  <td>{{ item.nobukti }}</td>
                  <td>{{ item.namacustomer }}</td>
                  <td>{{ item.status }}</td>
                  <td class="text-right">{{ formatPrice(item.jumlah) }}</td>
                  <td class="text-right">{{ formatPrice(item.discount) }}</td> 
                  <td class="text-right">{{ formatPrice(item.unik) }}</td>
                  <td class="text-right">{{ formatPrice(item.netto) }}</td> 
                  <td>
                    <v-btn x-small icon max-width="15px" class="ma-1">
                      <v-avatar size="15" @click="editItem(item)" tile>
                        <v-img :src="iconedit"></v-img>
                      </v-avatar>
                    </v-btn>
                  </td> 
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div> 

      <v-dialog
        v-model="dialogd"
        persistent
        max-width="700px"
      > 
        <v-card flat>
          <v-toolbar
            color="teal"
            dark
            flat
            tile
            max-height="50px"
          > 
          Detail</v-toolbar>
          <v-card-text class="justify-center">  
            <v-container>
               <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          Nama Paket
                        </th>
                        <th class="text-center">
                          Tgl
                        </th>
                        <th class="text-center">
                          Jam
                        </th>  
                        <th class="text-right">
                          Unit
                        </th>  
                        <th class="text-right">
                          Harga
                        </th>     
                        <th class="text-right">
                          Jumlah
                        </th>          
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in datatabeld"
                        :key="item.no"
                      >
                        <td>{{ item.namapaket }}</td>
                        <td>{{ item.tglawal }}</td> 
                        <td>{{ item.jam_awal }} - {{ item.jam_akhir }} </td>  
                        <td class="text-right">{{ item.qtyunit }}</td> 
                        <td class="text-right">{{ formatPrice(item.harga) }}</td>
                        <td class="text-right">{{ formatPrice(item.jumlah_d) }}</td>                      
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>  
            </v-container>
          </v-card-text>          
          <v-card-actions class="justify-end mt-n8 mr-4">             
            <v-btn
              color="blue darken-1"
              text
              @click="dialogd = false"
            >
              Close
            </v-btn>            
          </v-card-actions>
        </v-card>        
      </v-dialog>
    </v-card>
  </v-app>
</template>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from 'axios'; 

export default {
  components: {
    Vmenu
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      menudari: false,
      menusmp: false,
      iconedit: Constant.iconedit,
      iconrefresh: Constant.iconrefresh,
      iconfilter: Constant.iconfilter,
      srnobukti: "",  
      srcustomer: "",
      datatabel: [],  
      datatabeld: [],
      loading: true,  
      dialog: false,
      dialogd: false,
      dari: new Date().toISOString().substr(0, 10), 
      sampai: new Date().toISOString().substr(0, 10),  
      iconfilter: Constant.API_ADDR + "assets/images/filter.png" 
    };
  },
  methods: {
    async getdata() {  
      this.loading = true; 
      const datakirim = {
        idlog: Constant.IDLOG_APP, 
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,    
        dari: this.dari,
        sampai: this.sampai,    
        srnobukti: this.srnobukti,
        srcustomer: this.srcustomer,
        tipe:"RENTAL"    
      };  
     
      await axios
        .post(Constant.API_ADDR + "fakturjual/daftarjualantipe", datakirim)
        .then(response => {
          if (response.data.status === 102) 
          {
            this.datatabel = response.data.data;  
            this.dialog = false
            this.srnobukti = "" 
            this.srcustomer = ""
          }  
          else
          {
           if(response.data.status == 100)
           {
             alert(response.data.message)
             this.Constant.USER_APP = '';
             this.Constant.TOKEN_APP = ''; 
           } 
          }
        })
        .catch(errros => {
          console.log("error");
        }); 
      this.loading = false;  
    },
    async editItem(item) {  
      this.loading = true; 
      const datakirim = {
        idlog: Constant.IDLOG_APP, 
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,    
        nobukti: item.nobukti, 
        tipe: item.tipe
      };  
     
      await axios
        .post(Constant.API_ADDR + "fakturjual/daftarjualandetail", datakirim)
        .then(response => { 
          if (response.data.status === 102) 
          {
            this.datatabeld = response.data.data;  
            this.dialogd = true 
          }  
          else
          {
           if(response.data.status == 100)
           {
             alert(response.data.message)
             this.Constant.USER_APP = '';
             this.Constant.TOKEN_APP = ''; 
           } 
          }
        })
        .catch(errros => {
          console.log("error");
        }); 
      this.loading = false;   
    },
    deleteItem(item) {
      // this.loading = true;     
      // const datakirim = {
      //   user: Constant.USER_APP,
      //   token: Constant.TOKEN_APP,
      //   idlog: Constant.IDLOG_APP,  
      //   idbank: item.id 
      // };  
      // axios
      //   .post(Constant.API_ADDR + "bank/hapusbank", datakirim)
      //   .then((response) => {   
      //     if(response.data.status == 102)
      //     { 
      //       alert('sukses menghapus data')
      //       this.renewdata()  
      //     }
      //     else
      //     {
      //       alert(response.data.message)
      //     }
      //   })
      //   .catch((errros) => {
      //     console.log("error");
      //   });  
      // this.loading = false;  
    },    
    renewdata() 
    {
      this.srnobukti = ""
      this.srcustomer = ""
      this.getdata()       
    }, 
    manage()
    {  
      if (Constant.USER_APP != "" && Constant.TOKEN_APP != "") 
      { 
        // this.$router.push({ name: "Bank Manage", 
        //   params: { Id: '-1', Jenis:'tambahdata', 
        //   norek:'', nama:'', kode:'', 
        //   pemilik:'', alamat:'', telp:'', 
        //   pos:0, nomorakun:'-0000-' } 
        // });
      } 
    },
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  },
  created() 
  {
    Constant.IMENU = 'Penjualan Rental';
    this.dari = Constant.STARTDATE;
    this.getdata();
  },
  mounted() {},
};
</script>

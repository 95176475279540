<template>
  <v-app id="inspire">
    <Vmenu /> 
    <div class="forme">
      <v-container>
        <div>
          <div class="mt-3 title font-weight-bold">
            {{ menus }}
            <v-btn x-small icon max-width="35px" class="ml-2">
              <v-avatar size="35" @click="getmaster" tile>
                <v-img :src="icondownload"></v-img>
              </v-avatar>
            </v-btn>   
           
          </div> 
          <div class="text-center">
            
          </div> 
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          > 
            <v-row align="center" no-gutters>  
              <v-col cols="12" sm="6">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tgl"
                      label="Tanggal" 
                      class="pa-1"
                      persistent-hint 
                      v-bind="attrs" 
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tgl"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu> 
              </v-col>
              <v-col cols="12" sm="6"  >
                <v-text-field
                  v-model="nobukti"
                  :rules="NoRules"
                  label="Nobukti"
                  class="pa-1"
                  required 
                  @focus="getnewcode"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="6">
                <v-select
                  v-model="jenisjurnal"
                  :items="jeniss"
                  label="Jenis Jurnal" 
                  class="pa-1"
                  required
                ></v-select> 
              </v-col>
              <v-col cols="12" sm="6"></v-col>
              <v-col cols="12" sm="4" class="pa-1">
                <v-dialog
                  v-model="dialog"
                  persistent
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                      block
                      tile
                      color="primary" 

                      v-bind="attrs"
                      v-on="on"
                    >
                      Transaksi Detail 
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12" 
                          >
                            <v-autocomplete 
                              v-model="selectedakun.nomor"
                              :items="dataakun"
                              item-text="desk"
                              item-value="nomor"  
                              cache-items
                              :rules="[v => !!v || 'Item is required']"
                              label="Data Pos Perkiraan"
                              required  
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="desk2"
                              label="Keterangan" 
                            ></v-text-field>                            
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              type="number"
                              v-model="debet"
                              label="Debet" 
                            ></v-text-field>
                          </v-col>  
                          <v-col cols="12" sm="6">
                            <v-text-field
                              type="number"
                              v-model="kredit"
                              label="Kredit" 
                            ></v-text-field>
                          </v-col>  
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="simpandata"
                      >
                        Simpan
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                
              </v-col>
              <v-col cols="12" sm="4" class="pa-1"> 
                <p class="text-h6 font-weight-bold">
                  Total Debet Rp. {{totaldebet}}
                </p> 
              </v-col>
              <v-col cols="12" sm="4" class="pa-1"> 
                <p class="text-h6 font-weight-bold">
                  Total Kredit Rp. {{totalkredit}}
                </p> 
              </v-col>

              <!-- tabel -->
              <v-col cols="12" class="pa-1">
                <v-card flat>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              No Akun
                            </th>
                            <th class="text-left">
                              Nama
                            </th>
                            <th class="text-left">
                              Keterangan
                            </th>
                            <th class="text-left">
                              Debet
                            </th>
                            <th class="text-left">
                              Kredit
                            </th>
                            <th class="text-left">
                              Aksi
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in datatabel"
                            :key="item.id"
                          >
                            <td>{{ item.nomor }}</td>
                            <td>{{ item.nama }}</td>
                            <td>{{ item.keterangan }}</td>
                            <td>{{ item.debet }}</td> 
                            <td>{{ item.kredit }}</td> 
                            <td>
                              <v-btn x-small icon max-width="15px" class="ma-1">
                                <v-avatar size="15" @click="editItemd(item)" tile>
                                  <v-img :src="iconedit"></v-img>
                                </v-avatar>
                              </v-btn>
                              <v-btn x-small icon max-width="15px" class="ma-1">
                                <v-avatar  size="15" @click="deleteItemd(item)" tile>
                                  <v-img :src="iconhapus"></v-img>
                                </v-avatar>
                              </v-btn>   
                            </td> 
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                </v-card>  
              </v-col>  

              <v-col cols="12" sm="12" class="pa-1">
                <v-btn color="primary" small
                  :loading="loading"
                  :disabled="loading"
                  @click="simpandata">
                  Simpan
                </v-btn> 
                <v-btn color="error" small class="ml-2" @click="batal">
                  batal
                </v-btn> 
                <v-btn color="success" small class="ml-2" @click="tambahdata">
                  Tambah
                </v-btn>   
              </v-col>  
            </v-row>
          </v-form>
        </div> 
      </v-container>
    </div>
     
  </v-app>
</template>

<style scoped>
  .forme {
    width: 100%;
    height: 100%; 
    position: relative; 
    background-color: grey lighten-5;
  } 
</style>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from "axios";

export default {
  components: {
    Vmenu,
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      dialog:false,
      valid: true,
      menu1:false,
      loading:false,
      loadcetak:false,
      icondownload: Constant.icondownload,
      iconedit: Constant.iconedit,
      iconhapus: Constant.icondelete,
      vidh:-1,
      vidd:-1,
      vjenis:'tambahdata',
      menus:'', 
      totaldebet:0,
      totalkredit:0,
      tgl: new Date().toISOString().substr(0, 10), 
      nobukti:'', 
      jenisjurnal:'PENYESUAIAN',
      jeniss:['PENYESUAIAN', 'MEMORIAL'],
      selectedakun: { nomor: "0", desk: "-Pilih Akun Perkiraan-" }, 
      dataakun: [{nomor: "0", desk: "-Pilih Akun Perkiraan-"}],  
      desk2:'',
      debet:0,
      kredit:0,      
      datatabel:[],
      NoRules: [
        v => !!v || 'Field is required', 
      ],    
    };
  },
  methods: { 
    getnewcode()
    {
      this.loading = true;  
      if(this.vjenis == 'tambahdata')
      { 
        const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP,  
          search: this.tgl
        };  
        axios
          .post(Constant.API_ADDR + "jurnalpenyesuaian/nobuktibaru", datakirim)
          .then((response) => {  
            this.nobukti = response.data.data  
          })
          .catch((errros) => {
            console.log("error");
          }); 
      }
      this.loading = false;  
    },
    simpandata()
    {
      this.loading = true;   
      // kirim data
      const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP,  
          jenis: this.vjenis,
          idh: this.vidh,
          nobukti: this.nobukti,
          tanggal: this.tgl,
          jenisjurnal: this.jenisjurnal,
          idd:this.vidd,
          idakun: this.selectedakun.nomor,
          desk2: this.desk2,
          debet: this.debet,
          kredit: this.kredit,
        };  
      axios
        .post(Constant.API_ADDR + "jurnalpenyesuaian/simpanjurnal", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.selectedakun.nomor = "0"
            this.vjenis = 'editdata'
            this.vidh = response.data.datah.id
            this.datatabel = response.data.datad
            this.totaldebet = response.data.datah.totaldebet
            this.totalkredit = response.data.datah.totalkredit
            this.vidd = -1
            this.desk2 = ''
            this.debet = 0
            this.kredit = 0
            this.loading = false; 
          }
          else
          {
            alert(response.data.message)
            this.loading = false; 
          }

        })
        .catch((errros) => {
          console.log("error");
          this.loading = false; 
        });        
    },
    batal(){ 
      this.$router.push({ name: "Jurnal Penyesuaian" }); 
    },
    async getmaster(){
      this.loading = true; 
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP  
      };  
      await axios
        .post(Constant.API_ADDR + "api/datamaster", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.dataakun = response.data.dataakun4
          }
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false; 
    },
    tambahdetail(){
      this.selectedakun.nomor = '0'
      this.vidd = -1
      this.desk2 = ''
      this.kredit = 0
      this.debet = 0
      this.dialog = true
    },
    tambahdata(){
      this.vidh = -1
      this.vjenis = 'tambahdata' 
      this.nobukti = ''
      this.tgl = new Date().toISOString().substr(0, 10)
      this.jenisjurnal = "PENYESUAIAN" 
      this.totaldebet = 0
      this.totalkredit = 0
      this.datatabel = []
      this.selectedakun.nomor = "0"
    },
    editItemd(item){
      this.selectedakun.nomor = item.idnorek
      this.vidd = item.idd
      this.desk2 = item.keterangan
      this.debet = item.debet
      this.kredit = item.kredit
      this.dialog = true
    },
    deleteItemd(item){
      this.loading = true;    
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP,  
        idjurnalph: item.idh, 
        idjurnalpd: item.idd,
      };  
      axios
        .post(Constant.API_ADDR + "jurnalpenyesuaian/hapusdatad", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            alert('sukses menghapus data')
            this.datatabel = response.data.datad
            this.totaldebet = response.data.datah.totaldebet
            this.totalkredit = response.data.datah.totalkredit
            this.loading = false            
          }
          else
          {
            alert(response.data.message)
            this.loading = false;  
          }
        })
        .catch((errros) => {
          console.log("error");
          this.loading = false;  
        });   
    }, 
  },
  created() {
    Constant.IMENU = "Manage Jurnal Penyesuaian";
    this.getmaster();
    this.menus = Constant.IMENU;
    this.vidh = this.$route.params.Id
    this.vjenis = this.$route.params.Jenis 
    this.nobukti = this.$route.params.nobukti
    this.tgl = this.$route.params.tgl
    this.jenisjurnal = this.$route.params.Jenisjurnal 
    this.totaldebet = this.$route.params.totaldebet
    this.totalkredit = this.$route.params.totalkredit
    this.datatabel = this.$route.params.datatabel 
    this.selectedakun.nomor = "0" 
  },
  mounted() {},
  watch: {},
};
</script>

<template>
  <v-app id="inspire">
    <Vmenu />
    <v-card flat>
      <v-card-title class="mb-n4">
        {{ menu }} 
      </v-card-title>

      <v-card flat>
        <v-row align="center" class="ml-3 mt-0">           
          <v-btn
            tile
            small
            color="success"
            class="ml-1" 
            @click="renewdata" 
          >
            <v-avatar size="25" tile>
              <v-img :src="iconrefresh"></v-img>
            </v-avatar>
            Refresh
          </v-btn> 
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            no-gutters
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                tile
                small
                class="ml-1"
                color="error" 
                width="100px"               
                v-bind="attrs"
                v-on="on"                
              > 
                <v-avatar size="25" tile>
                  <v-img :src="iconfilter"></v-img>
                </v-avatar>
                Filter
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>   
                    <v-col cols="12">
                      <v-text-field
                        v-model="srnama"
                        label="Nama"
                        hint="Search Nama"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="srtelp"
                        label="Telp"
                        hint="Search Telp"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="sremail"
                        label="Email"
                        hint="Search Email"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                         
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="getdata"
                >
                  Search
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> 
        </v-row>  
      </v-card>

      <div class="col">
        <v-card flat>       
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr> 
                  <th class="text-left">
                    Nama
                  </th>
                  <th class="text-left">
                    Jenis Kelamin
                  </th>
                  <th class="text-left">
                    Tanggal Lahir
                  </th>
                  <th class="text-left">
                    Telp
                  </th> 
                  <th class="text-left">
                    Email
                  </th> 
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in datatabel"
                  :key="item.no"
                > 
                  <td>{{ item.nama }}</td>
                  <td>{{ item.jeniskelamin }}</td>
                  <td>{{ item.tgllahir }}</td>
                  <td>{{ item.telepon }}</td> 
                  <td>{{ item.email }}</td> 
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from 'axios'; 

export default {
  components: {
    Vmenu
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      search: "", 
      datatabel: [],  
      loading: true,  
      iconrefresh: Constant.iconrefresh,
      iconfilter: Constant.iconfilter,
      dialog: false, 
      srnama: "",
      srtelp:"",
      sremail:"",
    };
  },
  methods: {
    async getdata() {  
      this.loading = true; 
      const datakirim = {
        idlog: Constant.IDLOG_APP, 
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,        
        search: this.search,    
        srnama: this.srnama,
        srtelp: this.srtelp,
        sremail: this.sremail,
      };  
     
      await axios
        .post(Constant.API_ADDR + "pelanggan/daftarcustomer", datakirim)
        .then(response => {
          if (response.data.status === 102) 
          {
            this.datatabel = response.data.data;  
            this.srnama = "";
            this.srtelp = "";
            this.sremai = "";
          }  
          else
          {
           if(response.data.status == 100)
           {
             alert(response.data.message)
             this.Constant.USER_APP = '';
             this.Constant.TOKEN_APP = ''; 
           } 
          }
        })
        .catch(errros => {
          console.log("error");
        }); 
      this.loading = false;  
      this.dialog = false;
    },
    editItem(item) { 
       this.$router.push({ name: "Customer Manage", 
        params: { Id: item.id, Jenis:'editdata', 
        norek:item.norek, nama:item.nama, kode:item.kode, 
        pemilik:item.pemilik, alamat:item.alamat, telp:item.telp, 
        pos:item.pos, nomorakun:item.nomerakun } 
      });
    },
    renewdata() 
    {
      this.srnama = "";
      this.srtelp = "";
      this.sremai = "";
      this.getdata();
    }, 
  },
  created() 
  {
    Constant.IMENU = 'Customer';
    this.getdata();
  },
  mounted() {},
};
</script>

<template>
  <v-app id="inspire">
    <Vmenu />
    <v-card flat>
      <v-card-title>
        {{ menu }}
      </v-card-title>
      <div class="col">
        <v-card flat> 
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr> 
                  <th class="text-left">
                    Nama
                  </th> 
                  <th class="text-left">
                    Telp
                  </th>
                  <th class="text-left">
                    Virtual Account
                  </th> 
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in datatabel"
                  :key="item.id"
                >  
                  <td>{{ item.nama }}</td>
                  <td>{{ item.telp }}</td> 
                  <td>{{ item.va }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>
    </v-card>
   
  </v-app>
</template>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from 'axios'; 

export default {
  components: {
    Vmenu
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      datatabel: [], 
      loading: true,  
    };
  },
  methods: {
    async getdata() {  
      this.loading = true; 
      const datakirim = {
        idlog: Constant.IDLOG_APP, 
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP
      };  
     
      await axios
        .post(Constant.API_ADDR + "setting/datasetting", datakirim)
        .then(response => {
          if (response.data.status === 102) 
          {
            this.datatabel = response.data.data; 
          }  
          else
          {
           if(response.data.status == 100)
           {
             alert(response.data.message)
             this.Constant.USER_APP = '';
             this.Constant.TOKEN_APP = ''; 
           } 
          }
        })
        .catch(errros => {
          console.log("error");
        }); 
      this.loading = false;  
    }, 
  },
  created() 
  {
    Constant.IMENU = 'Setting';
    this.getdata();
  },
  mounted() {}, 
};
</script>

<template>
  <v-app id="inspire">
    <Vmenu /> 
    <div class="forme">
      <v-container>
        <div>
          <div class="mt-3 title font-weight-bold">
            {{ menus }}
          </div> 
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          > 
            <v-row align="center" no-gutters>  
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="nomor"
                  :rules="NoRules"
                  label="Nomor"
                  required
                  class="pa-1" 
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="8"></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="nama"
                  :rules="NoRules"
                  label="Nama"
                  required
                  class="pa-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8"></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="keterangan"
                  label="Keterangan"
                  required
                  class="pa-1"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="8"></v-col>
              <v-col cols="12" sm="4">
                <v-select
                  v-model="jenisakun"
                  :items="jenisakunn"
                  label="Jenis" 
                  class="pa-1" 
                ></v-select> 
              </v-col>
              <v-col cols="12" sm="8"></v-col>
              <v-col cols="12" sm="4">
                <v-select
                  v-model="level"
                  :items="levell"
                  label="Level No" 
                  class="pa-1" 
                ></v-select> 
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete 
                  v-model="selected.nomor"
                  :items="dataakun"
                  item-text="desk"
                  item-value="nomor"  
                  cache-items
                  :rules="[v => !!v || 'Item is required']"
                  label="Pilih Nomer Header"
                  required  
                  class="pa-1" 
                ></v-autocomplete>  
              </v-col> 

              <v-col cols="12" sm="4">
                <v-select
                  v-model="posisidk"
                  :items="posisidkk"
                  label="Posisi DK" 
                  class="pa-1" 
                ></v-select> 
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  v-model="posisinr"
                  :items="posisinrr"
                  label="Posisi NR" 
                  class="pa-1" 
                ></v-select> 
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  v-model="posisineraca"
                  :items="posisineracaa"
                  label="Posisi Neraca" 
                  class="pa-1" 
                ></v-select> 
              </v-col> 
              <v-col cols="12" sm="8"></v-col>

                <v-col cols="12" sm="6" class="pa-1">
                  <v-btn color="primary" 
                    :loading="loading"
                    :disabled="loading"
                    @click="simpandata">
                    Simpan
                  </v-btn> 
                  <v-btn color="error" class="ml-2" @click="batal">
                    batal
                  </v-btn>  
                </v-col>  
            </v-row>
          </v-form>
        </div> 
      </v-container>
    </div>
     
  </v-app>
</template>

<style scoped>
  .forme {
    width: 100%;
    height: 100%; 
    position: relative; 
    background-color: grey lighten-5;
  } 
</style>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from "axios";

export default {
  components: {
    Vmenu,
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      valid: true,
      loading:false, 
      vid:-1,
      vjenis:'tambahdata',
      menus:'',  
      selected: { nomor: "0", desk: "Akun Perkiraan" },
      dataakun: [{nomor: "0", desk: "Akun Perkiraan"}],  
      jenisakun:'JUDUL',
      jenisakunn:['JUDUL', 'DETIL'],
      level:'4',
      levell:['1', '2', '3', '4'],
      posisidk:'DEBET',
      posisidkk:['DEBET', 'KREDIT'],
      posisinr:'NERACA',
      posisinrr:['NERACA', 'R/L'],
      posisineraca:'DEBET',
      posisineracaa:['DEBET', 'KREDIT'],
      nomor:'',
      nama:'',
      keterangan:'',
      NumRules:[
        v => /^[0-9]+$/.test(v) || 'Must be Number Only' 
      ],
      NoRules: [
        v => !!v || 'Field is required', 
      ],    
    };
  },
  methods: { 
    simpandata()
    {
      this.loading = true;    
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        jenis: this.vjenis,
        id: this.vid,
        nomor: this.nomor,
        nama: this.nama,
        keterangan: this.keterangan, 
        jenislevel: this.jenisakun,
        levelno:this.level,
        nomorheader: this.selected.nomor,
        posisidk: this.posisidk,
        posisinr: this.posisinr,
        posisineraca: this.posisineraca 
      };  
      axios
        .post(Constant.API_ADDR + "perkiraan/simpandata", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.$refs.form.reset()
            this.selected.nomor       = "0"  
            this.vid                  = -1
            this.vjenis               = 'tambahdata'
            this.loading              = false;   
          }
          else
          {
            alert(response.data.message)
            this.loading = false;
          } 
        })
        .catch((errros) => {
          console.log("error");
          this.loading = false;
        });   
    },
    batal(){ 
      this.$router.push({ name: "Perkiraan" }); 
    },
    async getmaster(){
      this.loading = true; 
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP 
      };  
      await axios
        .post(Constant.API_ADDR + "api/datamaster", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.dataakun = response.data.dataakunheader 
          }
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false; 
    }, 
  },
  created() {
    Constant.IMENU = "Manage Perkiraan";
    this.getmaster(); 
    this.menus = Constant.IMENU;
    this.vid = this.$route.params.Id 
    this.vjenis = this.$route.params.Jenis   
    this.nomor = this.$route.params.nomor
    this.nama = this.$route.params.nama
    this.keterangan = this.$route.params.keterangan
    this.jenisakun = this.$route.params.jenislevel
    this.level = this.$route.params.levelno
    this.posisidk = this.$route.params.posisidk 
    this.posisinr = this.$route.params.posisinr 
    this.posisineraca = this.$route.params.posisineraca  
    this.selected.nomor = this.$route.params.nomorheader
  },
  mounted() {},
  watch: {},
};
</script>

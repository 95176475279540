<template>
  <v-app id="inspire">
    <Vmenu />
    <v-card flat>
      <v-card-title class="mb-n4">
        {{ menu }}        
      </v-card-title>

      <v-card flat>
        <v-row align="center" class="ml-3 mt-0"> 
          <v-btn
            tile
            small
            color="primary"
            class="mx-1"
            @click="manage" 
          > 
            <v-avatar size="25" tile>
              <v-img :src="iconadd"></v-img>
            </v-avatar>
            Tambah
          </v-btn>
          <v-btn
            tile
            small
            color="success"
            class="ml-1" 
            @click="renewdata" 
          >
            <v-avatar size="25" tile>
              <v-img :src="iconrefresh"></v-img>
            </v-avatar>
            Refresh
          </v-btn> 
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            no-gutters
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                tile
                small
                class="ml-1"
                color="error" 
                width="100px"               
                v-bind="attrs"
                v-on="on"                
              > 
                <v-avatar size="25" tile>
                  <v-img :src="iconfilter"></v-img>
                </v-avatar>
                Filter
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>                    
                    <v-col cols="12">
                      <v-text-field
                        v-model="srnorek"
                        label="No Rekening"
                        hint="Search No Rekening"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="srnama"
                        label="Nama Bank"
                        hint="Search Nama Bank"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="srpemilik"
                        label="Nama Rekening"
                        hint="Nama Rekening"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="srnoakun"
                        label="Nomer Akun"
                        hint="Nomer Akun"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="srnamaakun"
                        label="Nama Akun"
                        hint="Nama Akun"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6"></v-col>  
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="getdata"
                >
                  Search
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> 
        </v-row>  
      </v-card>

      <div class="col">
        <v-card flat>       
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Nomor Rekening
                  </th> 
                  <th class="text-left">
                    Nama Bank
                  </th>
                  <th class="text-left">
                    Pemilik
                  </th>
                  <th class="text-left">
                    Nomor Akun 
                  </th>
                  <th class="text-left">
                    Nama Akun
                  </th> 
                  <th class="text-left">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in datatabel"
                  :key="item.no"
                >
                  <td>{{ item.norek }}</td> 
                  <td>{{ item.nama }}</td>
                  <td>{{ item.pemilik }}</td>
                  <td>{{ item.nomerakun }}</td>
                  <td>{{ item.namaakun }}</td> 
                  <td>
                    <v-btn x-small icon max-width="15px" class="ma-1">
                      <v-avatar size="15" @click="editItem(item)" tile>
                        <v-img :src="iconedit"></v-img>
                      </v-avatar>
                    </v-btn>
                    <v-btn x-small icon max-width="15px" class="ma-1">
                      <v-avatar  size="15" @click="deleteItem(item)" tile>
                        <v-img :src="iconhapus"></v-img>
                      </v-avatar>
                    </v-btn> 
                  </td> 
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from 'axios'; 

export default {
  components: {
    Vmenu
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      search: "", 
      datatabel: [],  
      loading: true,  
      iconedit: Constant.iconedit,
      iconhapus: Constant.icondelete,
      iconadd: Constant.iconadd,
      iconrefresh: Constant.iconrefresh,
      iconfilter: Constant.iconfilter,
      dialog:false,
      srnorek:"",
      srnama:"",
      srpemilik:"",
      srnoakun:"",
      srnamaakun:""
    };
  },
  methods: {
    async getdata() {  
      this.loading = true; 
      const datakirim = {
        idlog: Constant.IDLOG_APP, 
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,        
        search: this.search,    
        srnorek: this.srnorek,
        srnama: this.srnama,
        srpemilik: this.srpemilik,
        srnoakun: this.srnoakun,
        srnamaakun: this.srnamaakun,
      };   
      await axios
        .post(Constant.API_ADDR + "bank/daftarbank", datakirim)
        .then(response => {
          if (response.data.status === 102) 
          {
            this.datatabel = response.data.data;  
            this.srnorek = "";
            this.srnama = "";
            this.srpemilik = "";
            this.srnoakun = ""; 
            this.srnamaakun = "";
          }  
          else
          {
           if(response.data.status == 100)
           {
             alert(response.data.message)
             this.Constant.USER_APP = '';
             this.Constant.TOKEN_APP = ''; 
           } 
          }
        })
        .catch(errros => {
          console.log("error");
        });       
      this.loading = false;    
      this.dialog = false;
    },
    getdatanew(){
      const newdata = []
      if(this.search.length > 0)
      {
        const data = this.datatabel      
        const temp = []
        var self = this;
        
        return data.filter(function (person) {
            return person.norek.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
            || person.nama.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
        }); 
      } 
    },    
    editItem(item) { 
       this.$router.push({ name: "Kas Dan Bank Manage", 
        params: { Id: item.id, Jenis:'editdata', 
        norek:item.norek, nama:item.nama, kode:item.kode, 
        pemilik:item.pemilik, alamat:item.alamat, telp:item.telp, 
        pos:item.pos, nomorakun:item.nomerakun } 
      });
    },
    deleteItem(item) {
      this.loading = true;     
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP,  
        idbank: item.id 
      };  
      axios
        .post(Constant.API_ADDR + "bank/hapusbank", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            alert('sukses menghapus data')
            this.renewdata()  
          }
          else
          {
            alert(response.data.message)
          }
        })
        .catch((errros) => {
          console.log("error");
        });  
      this.loading = false;  
    },    
    renewdata() 
    {
      this.srnorek = "";
      this.srnama = "";
      this.srpemilik = "";
      this.srnoakun = ""; 
      this.srnamaakun = "";
      this.getdata();
    }, 
    manage()
    {  
      if (Constant.USER_APP != "" && Constant.TOKEN_APP != "") 
      { 
        this.$router.push({ name: "Kas Dan Bank Manage", 
          params: { Id: '-1', Jenis:'tambahdata', 
          norek:'', nama:'', kode:'', 
          pemilik:'', alamat:'', telp:'', 
          pos:0, nomorakun:'-0000-' } 
        });
      } 
    },
    
  },
  created() 
  {
    Constant.IMENU = 'Bank';
    this.getdata();
  },
  mounted() {},
};
</script>

<template> 
  <v-container fluid justify="center">      
    <v-row width=100% justify="start" class="ml-3">
      <v-btn depressed color="primary" small v-if="print === '0'" @click="printdata()">PRINT</v-btn> 
    </v-row>
    <v-row class="text-center mt-n4"> 
      <v-col>  
        <p class="subtitle-2 font-weight-bold mb-0">
          {{ office }}
        </p> 
        <p class="text-subtitle-2 red--text font-weight-bold mt-n2 mb-0">
          {{ jenis }}
        </p> 
        <p class="text-overline font-weight-regular mt-n2 mb-0">
          {{ periode }}
        </p>
      </v-col> 
    </v-row>

    <div class="container-fluid">
      <v-row justify="center" width=100%> 
        <v-col cols="6">
          <v-progress-linear
            color="teal accent-4"
            indeterminate
            rounded
            height="4" 
            :active="progress"
        ></v-progress-linear> 
        </v-col>
      </v-row>
    </div>

    <v-container fluid>
      <table class="tabel">
        <tr>
          <th rowspan="2">Nama Akun</th>
          <th colspan="2">Neraca Awal</th>
          <th colspan="2">Mutasi</th>
          <th colspan="2">Neraca Saldo</th>
          <th colspan="2">Penyesuaian</th>
          <th colspan="2">Laba Rugi</th>
          <th colspan="2">Neraca Akhir</th>
        </tr>
        <tr>
          <th>Debet</th>
          <th>Kredit</th>
          <th>Debet</th>
          <th>Kredit</th>
          <th>Debet</th>
          <th>Kredit</th>
          <th>Debet</th>
          <th>Kredit</th>
          <th>Debet</th>
          <th>Kredit</th>
          <th>Debet</th>
          <th>Kredit</th> 
        </tr>
        <tr v-for="item in datatabel" :key="item.urut">
          <td>
            <v-btn v-if="print === '0'"
              color="teal"
              fab 
              max-height="10px"
              max-width="10px"
              @click="editItem(item)"
            >  
            </v-btn>   
            {{ item.nomor}} - {{ item.nama }}</td>
          <td class="text-right">{{ formatPrice(item.saldoawal_debet) }}</td>
          <td class="text-right">{{ formatPrice(item.saldoawal_kredit) }}</td>
          <td class="text-right">{{ formatPrice(item.debet) }}</td>
          <td class="text-right">{{ formatPrice(item.kredit) }}</td>
          <td class="text-right">{{ formatPrice(item.neracasaldo_debet) }}</td>
          <td class="text-right">{{ formatPrice(item.neracasaldo_kredit) }}</td>
          <td class="text-right">{{ formatPrice(item.penyesuaian_debet) }}</td>
          <td class="text-right">{{ formatPrice(item.penyesuaian_kredit) }}</td>
          <td class="text-right">{{ formatPrice(item.rl_debet) }}</td>
          <td class="text-right">{{ formatPrice(item.rl_kredit) }}</td>
          <td class="text-right">{{ formatPrice(item.saldoakhir_debet) }}</td>
          <td class="text-right">{{ formatPrice(item.saldoakhir_kredit) }}</td> 
        </tr>  
        <tfoot class="foot">
          <tr>
            <td>TOTAL</td> 
            <td class="text-right">{{ formatPrice(tad) }}</td>
            <td class="text-right">{{ formatPrice(tak) }}</td>
            <td class="text-right">{{ formatPrice(tmd) }}</td>
            <td class="text-right">{{ formatPrice(tmk) }}</td>
            <td class="text-right">{{ formatPrice(tsd) }}</td>
            <td class="text-right">{{ formatPrice(tsk) }}</td>
            <td class="text-right">{{ formatPrice(tpd) }}</td>
            <td class="text-right">{{ formatPrice(tpk) }}</td>
            <td class="text-right">{{ formatPrice(tld) }}</td>
            <td class="text-right">{{ formatPrice(tlk) }}</td>
            <td class="text-right">{{ formatPrice(ted) }}</td> 
            <td class="text-right">{{ formatPrice(tek) }}</td> 
        </tr> 
        </tfoot>
      </table>
    </v-container>      
  </v-container>
   
</template> 

<style> 
  .tabel {
    font-family: Arial, Helvetica, sans-serif; 
    font-size: 11px;   
    border-collapse: collapse;
    width: 100%;
  }

  .tabel td, .tabel th {
    border: 0.3px solid white;
    padding: 5px;
  }

  .tabel tr:nth-child(even){background-color: #f2f2f2;}

  .tabel tr:hover {background-color: #ddd;}

  .tabel th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: teal;
    color: white;
    font-size: 11px;     
  }
  .foot {
    text-align: right;
    font-size: 12;
    font-weight: bold;
  }
</style>

<script>
import axios from "axios";  
import Constant from "@/components/Constant.vue";

export default {
  name: "LJU",

  data: () => ({ 
    token: "",
    datatabel : [],
    office: "",
    jenis: "",
    periode: "",    
    print:0,
    progress: true,
    tad: 0,
    tak: 0,
    tmd: 0,
    tmk: 0,
    tsd: 0,
    tsk: 0,
    tpd: 0,
    tpk: 0,
    tld: 0,
    tlk: 0,
    ted: 0,
    tek: 0,
  }),
  methods: { 
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getclassheader(level){ 
      if(level != 4)
      {
        return "text-left font-weight-bold" 
      }
      return "text-left font-weight-regular" 
    },
    async getdata(){  
      await axios 
        .post(localStorage.getItem("API_ADDR") + "viewlapkeu/viewlaporan", {token: this.token})
        .then((response) => {
          if (response.data.status === 102 || response.data.status === "102") 
          { 
            this.datatabel = response.data.data
            this.jenis = response.data.jenis
            this.periode = response.data.periode           
            this.office = response.data.office 
            this.progress = false
            this.tad = response.data.tad
            this.tak = response.data.tak
            this.tmd = response.data.tmd
            this.tmk = response.data.tmk
            this.tsd = response.data.tsd
            this.tsk = response.data.tsk
            this.tpd = response.data.tpd
            this.tpk = response.data.tpk
            this.tld = response.data.tld
            this.tlk = response.data.tlk
            this.ted = response.data.ted
            this.tek = response.data.tek
            this.cetakdata()
          } else {
            console.log("error");  
          }
        })
        .catch((errros) => {
          console.log("error"); 
        });
       
    },
    editItem(item){
      const datakirim = {
        user: localStorage.getItem('USER_APP'),
        token: localStorage.getItem('TOKEN_APP'),
        idlog: localStorage.getItem('IDLOG_APP'), 
        jenis: 'Buku Besar',  
        aksi: 'PDF',  
        periode: this.periode,  
        pfilter1: 'SEMUA',  
        pfilter2: 'norek',  
        pfilter3: 'Sama Dengan',  
        pfilter4: item.nomor   
      };  
      axios
      .post(Constant.API_ADDR + "laporankeuangan/request", datakirim) 
      .then((response) => {   
        if(response.data.status == 102)
        { 
          let routeData = this.$router.resolve({ name: 'Buku Besar', query: {noinv: response.data.data, print:0 }});              
          window.open(routeData.href, '_blank'); 
        }
        else
        {
          if(response.data.status == 100)
          {
            localStorage.clear()
            alert('Session Expired')
          }
          else
          {
            alert(response.data.message)
          } 
        }
      })
      .catch((errros) => {
        console.log("error");
      })  
    },
    printdata(){
      window.open(Constant.API_ADDR + "printpreviewlapkeu/cetak/" + this.token)      
    },
  },
  created() 
  {
    this.token = this.$route.query.noinv 
    this.print = this.$route.query.print  
    this.getdata() 
  },

};
</script>


<template>
  <v-container fluid>
    <v-row width=100% justify="start" class="ml-3">
      <v-btn depressed color="primary" small v-if="print === '0'" @click="printdata()">PRINT</v-btn>
    </v-row>
    <v-row class="text-center mt-n4"> 
      <v-col>  
        <p class="subtitle-2 font-weight-bold mb-0">
          {{ office }}
        </p> 
        <p class="text-subtitle-2 red--text font-weight-bold mt-n2 mb-0">
          {{ jenis }}
        </p> 
        <p class="text-overline font-weight-regular mt-n2 mb-0">
          {{ periode }}
        </p>
      </v-col> 
    </v-row>

    <div class="container-fluid">
      <v-row justify="center" width=100%> 
        <v-col cols="6">
          <v-progress-linear
            color="teal accent-4"
            indeterminate
            rounded
            height="4" 
            :active="progress"
        ></v-progress-linear> 
        </v-col>
      </v-row>
    </div>
    
    
    <v-row class="mt-n4">
      <!-- tabel data --> 
      <v-col class="mb-5" cols="12">
        <v-simple-table dense>
          <template v-slot:default> 
            <tbody>
              <tr 
                v-for="item in datatabel"
                :key="item.nourut"
              > 
                <td :class="getclassheader(item.levelnodebet)" width="70px">                                    
                  <v-btn v-if="print === '0' && item.levelnodebet === '4'"
                    color="teal"
                    class="mx-2"
                    fab 
                    max-height="10px"
                    max-width="10px"
                    @click="editItem(item.norekdebet)"
                  >  
                  </v-btn>                 
                  {{ item.namadebet }}</td> 
                <td class="text-caption text-right" width="40px">{{ formatPrice(item.saldoakhird) }}</td> 
                <td :class="getclassheader(item.levelnokredit)" width="70px"> 
                  <v-btn v-if="print === '0' && item.levelnokredit === '4'"
                    color="teal"
                    class="mx-2"
                    fab 
                    max-height="10px"
                    max-width="10px"
                    @click="editItem(item.norekkredit)"
                  >  
                  </v-btn>   
                  {{ item.namakredit }}</td> 
                <td class="text-right" width="40px">{{ formatPrice(item.saldoakhirk) }}</td>                     
              </tr>
              <tr>
                <td class = "font-weight-black" width="70px">Total Debet</td> 
                <td class="font-weight-black text-right" width="40px">{{ formatPrice(debet) }}</td> 
                <td class = "font-weight-black" width="70px">Total Kredit</td> 
                <td class="font-weight-black text-right" width="40px">{{ formatPrice(kredit) }}</td> 
              </tr>
            </tbody>
          </template>
        </v-simple-table>         
      </v-col> 
    </v-row>  
  </v-container>
   
</template> 

<script>
import axios from "axios";  
import Constant from "@/components/Constant.vue";

export default {
  name: "LNR",

  data: () => ({ 
    token: "",
    datatabel : [],
    office: "",
    jenis: "",
    periode: "",
    debet:0,
    kredit:0,
    print:0,
    progress: true
  }),
  methods: { 
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getclassheader(level){ 
      if(level != 4)
      {
        return "text-caption font-weight-black" 
      }
      return "text-caption font-weight-regular" 
    },
    async getdata(){  
      await axios 
        .post(localStorage.getItem("API_ADDR") + "viewlapkeu/viewlaporan", {token: this.token})
        .then((response) => {
          if (response.data.status === 102 || response.data.status === "102") 
          {
            this.datatabel = response.data.data
            this.jenis = response.data.jenis
            this.periode = response.data.periode
            this.debet = response.data.debet
            this.kredit = response.data.kredit  
            this.office = response.data.office
            this.progress = false
            this.cetakdata()
          } else {
            this.progress = false
            console.log("error");  
          }
        })
        .catch((errros) => {
          this.progress = false
          console.log("error"); 
        });
       
    },
    editItem(item){
      const datakirim = {
        user: localStorage.getItem('USER_APP'),
        token: localStorage.getItem('TOKEN_APP'),
        idlog: localStorage.getItem('IDLOG_APP'), 
        jenis: 'Buku Besar',  
        aksi: 'PDF',  
        periode: this.periode,  
        pfilter1: 'SEMUA',  
        pfilter2: 'norek',  
        pfilter3: 'Sama Dengan',  
        pfilter4: item  
      };  
      axios
      .post(Constant.API_ADDR + "laporankeuangan/request", datakirim) 
      .then((response) => {   
        if(response.data.status == 102)
        { 
          let routeData = this.$router.resolve({ name: 'Buku Besar', query: {noinv: response.data.data, print:0 }});              
          window.open(routeData.href, '_blank'); 
        }
        else
        {
          if(response.data.status == 100)
          {
            localStorage.clear()
            alert('Session Expired')
          }
          else
          {
            alert(response.data.message)
          } 
        }
      })
      .catch((errros) => {
        console.log("error");
      })  
    },
    printdata(){
      window.open(Constant.API_ADDR + "printpreviewlapkeu/cetak/" + this.token)      
    },
  },
  created() 
  {
    this.token = this.$route.query.noinv 
    this.print = this.$route.query.print  
    this.getdata() 
  },

};
</script>

<style>
  @media print{@page {size: A4 landscape}}
    
</style>
